import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';
import { objectToQueryString } from '../../../../Utils/Common/utils';

const GetInternURL = 'host-company/browse/candidates';

export const GetInternListApi = createApi({
  reducerPath: 'internListApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['InternList'],
  endpoints: (builder) => ({
    getInternList: builder.query({
      query: (body) => {
        if (body) {
          let queryString = objectToQueryString(body);
          let updatedUrl = `${GetInternURL}?${queryString}`;
          return { url: updatedUrl, method: 'GET' };
        }
      },
    }),
  }),
});

export const { useGetInternListQuery, usePrefetch } = GetInternListApi;

export default GetInternListApi.reducer;
