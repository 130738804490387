import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const ioUrl = '/host-company/internship-plan/list';
const pocsUrl = '/host-company/pocs';
export const getHostCompanyPocDetailsByIdURL = '/host-company/poc/user';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
  pocsListStatus: 'idle',
  pocsListError: null,
  pocsList: null,
  pocsListIsLoading: false,
  getInternshipPlansRequest: {},
  pocsListByIdStatus: 'idle',
  pocsListByIdError: null,
  pocsListByIdResponse: null,
  pocsListByIdLoading: null,
  internDetails: {
    uuid: '',
    career_field: '',
    career_field_id: '',
    ioId: '',
  },
};

export const getInternshipPlans = createAsyncThunk(
  'getInternshipPlans',
  async (request, { rejectWithValue, dispatch }) => {
    const result = await axios
      .post(ioUrl, request)
      .then((response) => {
        dispatch(saveTheRequestParams(request));
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

export const getInternshipPlansPocs = createAsyncThunk(
  'getInternshipPlansPocs',
  async (request, { rejectWithValue }) => {
    const params = new URLSearchParams(request);
    const result = await axios
      .get(`${pocsUrl}?${params}`)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);
export const getHostCompanyPocDetailsById = createAsyncThunk(
  'getHostCompanyPocDetailsById',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(`${getHostCompanyPocDetailsByIdURL}?user_id=${request?.user_id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternshipPlansSlice = createSlice({
  name: 'GetInternshipPlans',
  initialState,
  reducers: {
    resetPlanStatus: (state) => {
      state.status = 'idle';
    },
    saveTheRequestParams: (state, action) => ({
      ...state,
      getInternshipPlansRequest: action?.payload,
    }),
    saveTheInterDetails: (state, action) => ({
      ...state,
      internDetails: {
        uuid: action?.payload?.uuid,
        career_field: action?.payload?.career_field,
        career_field_id: action?.payload?.career_field_id,
        ioId: action?.payload?.ioId,
      },
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(getInternshipPlans.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getInternshipPlans.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
        state.isLoading = false;
      })
      .addCase(getInternshipPlans.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      })
      .addCase(getInternshipPlansPocs.pending, (state) => {
        state.pocsListStatus = 'Pending';
        state.pocsListIsLoading = true;
      })
      .addCase(getInternshipPlansPocs.fulfilled, (state, action) => {
        state.pocsListStatus = 'Success';
        state.pocsList = action?.payload?.data?.payload;
        state.pocsListIsLoading = false;
      })
      .addCase(getInternshipPlansPocs.rejected, (state, error) => {
        state.pocsListStatus = 'Failed';
        state.pocsListError = error;
        state.pocsListIsLoading = false;
      })
      .addCase(getHostCompanyPocDetailsById.pending, (state) => {
        state.pocsListByIdStatus = 'Pending';
        state.pocsListByIdLoading = true;
      })
      .addCase(getHostCompanyPocDetailsById.fulfilled, (state, action) => {
        state.pocsListByIdStatus = 'Success';
        state.pocsListByIdLoading = false;
        state.pocsListByIdResponse = action?.payload?.data?.payload;
      })
      .addCase(getHostCompanyPocDetailsById.rejected, (state, error) => {
        state.pocsListByIdStatus = 'Failed';
        state.pocsListByIdError = error;
        state.pocsListByIdLoading = false;
      });
  },
});

export const getInternshipPlansState = (state) => state.GetInternshipPlans;
export const { resetPlanStatus, saveTheRequestParams, saveTheInterDetails } =
  GetInternshipPlansSlice.actions;
export default GetInternshipPlansSlice.reducer;
