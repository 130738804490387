import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';

const URL = 'host-company/interview/batch/confirmation';

export const GetConfirmInterviewDetailApi = createApi({
  reducerPath: 'getConfirmInterviewDetailApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getConfirmInterview: builder.mutation({
      query: (batchId) => ({ url: URL + '/' + batchId, method: 'GET', body :null }),
    }),
  }),
});

export const { useGetConfirmInterviewMutation } = GetConfirmInterviewDetailApi;

export default GetConfirmInterviewDetailApi.reducer;
