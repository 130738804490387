import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = '/host-company/profile/update-basic-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveBasicDetails = createAsyncThunk(
  'saveBasicDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);



const SaveBasicDetailsSlice = createSlice({
  name: 'SaveBasicDetails',
  initialState,
  reducers: {
    resetBasicDetails: () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(saveBasicDetails.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(saveBasicDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(saveBasicDetails.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSaveBasicDetailsState = (state) => state.SaveBasicDetails;
export const { resetBasicDetails } = SaveBasicDetailsSlice.actions;
export default SaveBasicDetailsSlice.reducer;
