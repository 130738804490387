import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/dashboard/list-candidate';
const interviewsAndOffersCountUrl = '/host-company/dashboard/interviews/offers/count';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
  interviewsAndOffersCountStatus: 'idle',
  interviewsAndOffersCountError: null,
  interviewsAndOffersCountResponse: null,
  interviewsAndOffersCountIsLoading: false,
};

export const getListCandidate = createAsyncThunk(
  'getListCandidate',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

export const interviewsAndOffersCount = createAsyncThunk(
  'interviewsAndOffersCount',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(interviewsAndOffersCountUrl, request)
      .then((response) => response?.data?.data?.payload)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetListCandidateSlice = createSlice({
  name: 'GetListCandidate',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getListCandidate.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getListCandidate.fulfilled, (state, action) => {
        state.status = action.payload?.data?.isSuccess ? 'Success' : 'Failed';
        state.response = action.payload;
        state.isLoading = false;
      })
      .addCase(getListCandidate.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      })
      .addCase(interviewsAndOffersCount.pending, (state) => {
        state.interviewsAndOffersCountStatus = 'Pending';
        state.interviewsAndOffersCountIsLoading = true;
      })
      .addCase(interviewsAndOffersCount.fulfilled, (state, action) => {
        state.interviewsAndOffersCountStatus = 'Success';
        state.interviewsAndOffersCountResponse = action.payload;
        state.interviewsAndOffersCountIsLoading = false;
      })
      .addCase(interviewsAndOffersCount.rejected, (state) => {
        state.interviewsAndOffersCountStatus = 'Failed';
        state.interviewsAndOffersCountIsLoading = false;
      });
  },
});

export const getListCandidateState = (state) => state.GetListCandidate;
export default GetListCandidateSlice.reducer;
