import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';
import { getUtmParams } from 'Utils/Common/utils';

const checkEmailUrl = '/common-services/validate/v2/email';
const generateOtpUrl = '/common-services/otp/v2/create';
const verifyOtpUrl = '/common-services/otp/v2/verify';
const getOtpConfigUrl = 'common-services/global-setting/get';
const getCompanyDetailsByUuidUrl = '/host-company/get/company-detail/';
const getCompanyRoles = '/common-services/host-company-roles';
const saveBasicInfoUrl = '/host-company/register/pocs';
const saveCompanyDetailsUrl = '/host-company/register/companies';
const saveInvitedUserDetailsURL = '/host-company/invited/user';

export const SignInSignUpApi = createApi({
  reducerPath: 'SignInSignUpApi',
  baseQuery: customBaseQuery,
  tagTypes: ['company-details'],
  endpoints: (builder) => ({
    checkEmail: builder.query({
      query: (query) => {
        return { url: `${checkEmailUrl}?${query}`, method: 'GET', body: null };
      },
    }),
    generateOtp: builder.mutation({
      query: (body) => ({
        url: generateOtpUrl,
        method: 'POST',
        body,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (body) => {
        const utmParams = getUtmParams(window.location.href);
        const queryParams = new URLSearchParams(utmParams).toString();
        const url = `${verifyOtpUrl}?${queryParams}`;
        return {
          url,
          method: 'POST',
          body,
        };
      },
    }),
    getOtpConfig: builder.query({
      query: () => {
        return { url: getOtpConfigUrl, method: 'GET', body: null };
      },
    }),
    getCompanyDetailsByUuid: builder.query({
      query: (uuid) => {
        return { url: getCompanyDetailsByUuidUrl + uuid, method: 'GET', body: null };
      },
      providesTags: ['company-details'],
    }),
    getCompanyRoles: builder.query({
      query: () => {
        return { url: getCompanyRoles, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload;
      },
    }),
    saveBasicInfo: builder.mutation({
      query: (body) => ({
        url: saveBasicInfoUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['company-details'],
    }),
    saveCompanyDetails: builder.mutation({
      query: (body) => ({
        url: saveCompanyDetailsUrl,
        method: 'POST',
        body,
      }),
    }),
    saveInvitedUserDetails: builder.mutation({
      query: (body) => ({
        url: saveInvitedUserDetailsURL,
        method: 'PUT',
        body,
      }),
    }),
  }),
});
export const {
  useLazyCheckEmailQuery,
  useGenerateOtpMutation,
  useVerifyOtpMutation,
  useGetOtpConfigQuery,
  useGetCompanyDetailsByUuidQuery,
  useGetCompanyRolesQuery,
  useSaveBasicInfoMutation,
  useSaveCompanyDetailsMutation,
  useSaveInvitedUserDetailsMutation,
} = SignInSignUpApi;
export default SignInSignUpApi.reducer;
