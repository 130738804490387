import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';

const GetInternAvailableURL = 'host-company/browse-intern/v2/intern/available';

export const GetInternAvailableApi = createApi({
  reducerPath: 'internAvailableApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['InternAvailable'],
  endpoints: (builder) => ({
    getInternAvailable: builder.query({
      query: (uuid) => ({ url: `${GetInternAvailableURL}/${uuid}`, method: 'GET' }),
      providesTags: ['InternAvailable'],
    }),
  }),
});

export const { useGetInternAvailableQuery } = GetInternAvailableApi;

export default GetInternAvailableApi.reducer;
