import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';
import { objectToQueryString } from '../../../../Utils/Common/utils';

const url = 'common-services/job-functions';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getJobFunctions = createAsyncThunk(
  'getJobFunctions',
  async (request, { rejectWithValue }) => {
    let queryString = objectToQueryString(request);
    let updatedUrl = queryString ? `${url}?${queryString}` : url;
    const result = await axios
      .get(updatedUrl)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetJobFunctionsSlice = createSlice({
  name: 'GetJobFunctions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getJobFunctions.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getJobFunctions.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getJobFunctions.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getJobFunctionsState = (state) => state.GetJobFunctions;
export default GetJobFunctionsSlice.reducer;
