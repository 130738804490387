import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'intern-service/application/batch-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getInternBatchDetails = createAsyncThunk(
  'getInternBatchDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternBatchDetailsSlice = createSlice({
  name: 'GetInternBatchDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* get details of intern state handling */

      .addCase(getInternBatchDetails.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternBatchDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getInternBatchDetails.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternBatchDetailsState = (state) => state.GetInternBatchDetails;
export default GetInternBatchDetailsSlice.reducer;
