import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const getInternDetailsByUuidUrl = 'host-company/browse-intern/intern/';

export const InternDetailsApi = createApi({
  reducerPath: 'InternDetailsApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getInternDetailsByUuid: builder.query({
      query: (uuid) => {
        const url = getInternDetailsByUuidUrl + uuid;
        return { url, method: 'GET', body: null };
      },
    }),
  }),
});

export const { useGetInternDetailsByUuidQuery } = InternDetailsApi;
export default InternDetailsApi.reducer;
