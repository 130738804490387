import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

const SuccessPageSlice = createSlice({
  name: 'SuccessPageData',
  initialState,
  reducers: {
    addValues: {
      reducer(state, action) {
        state.data = { ...state.data, ...action?.payload };
      },
    },
    getValues: {
      reducer(state) {
        return state?.data;
      },
    },
  },
});

export const { addValues, getValues } = SuccessPageSlice.actions;
export const getSuccessPageState = (state) => state.SuccessPageData;

export default SuccessPageSlice.reducer;
