import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const updateAccountDetailsUrl = '/intern-service/update-account-settings-details';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateAccountDetails = createAsyncThunk(
  'updateAccountDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(updateAccountDetailsUrl, request)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const UpdateAccountDetailsSlice = createSlice({
  name: 'UpdateAccountDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update account details state handling */
      .addCase(updateAccountDetails.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(updateAccountDetails.fulfilled, (state, action) => ({
        ...state,
        status: action?.payload?.isSuccess ? 'Success' : 'Failed',
        response: action?.payload?.payload,
        message: !action.payload.isSuccess
          ? action.payload?.payload?.error?.description || action.payload.message
          : '',
      }))
      .addCase(updateAccountDetails.rejected, (state) => ({ ...state, status: 'Failed' }));
  },
});

export const getUpdateAccountDetailsState = (state) => state.UpdateAccountDetails;
export default UpdateAccountDetailsSlice.reducer;
