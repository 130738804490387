import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';

export interface InternInterviewTime {
  status: number;
  data: {
    message: string;
    isSuccess: boolean;
    payload: {
      minimum_date: string;
      maximum_date: string;
    };
  };
}

const GetInternInterviewTime = 'intern-service/intern-dashboard/interview/time';

export const GetInternInterviewTimeApi = createApi({
  reducerPath: 'internInterviewTimeApi',
  baseQuery: customBaseQuery,
  tagTypes: ['internInterviewTimeApi'],
  endpoints: (builder) => ({
    getInternInterviewTime: builder.query<InternInterviewTime, string>({
      query: (id) => ({
        url: `${GetInternInterviewTime}/${id}`,
        method: 'GET',
        body: null,
      }),
      providesTags: ['internInterviewTimeApi'],
    }),
  }),
});

export const { useGetInternInterviewTimeQuery } = GetInternInterviewTimeApi;

export default GetInternInterviewTimeApi.reducer;
