import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const URL = 'host-company/register/create-user';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/* structure of request to be
@request = 
{
    "email": "test2@yahoo.com",
    "otp_hash": "64d4d592bc99f84d93f5e3df4",
    "otp": 475437
} 
 */

export const createUser = createAsyncThunk('createUser', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(URL, request)
    .then((response) => response.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const CreateUserSlice = createSlice({
  name: 'CreateUser',
  initialState,
  reducers: {
    setCreateStatusIdle: (state) => {
      state.status = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      /* generate verifyOTPWithHash state handling */
      .addCase(createUser.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action.payload.response;
      });
  },
});

export const getCreateUserState = (state) => state.CreateUser;
export const { setCreateStatusIdle } = CreateUserSlice.actions;

export default CreateUserSlice.reducer;
