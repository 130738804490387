import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = '/host-company/offer/update-intern-offer';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateOfferStep1 = createAsyncThunk('updateOfferStep1', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(url, request)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const UpdateOfferStep1Slice = createSlice({
  name: 'UpdateOfferStep1',
  initialState,
  reducers: {
    resetUpdateOfferStatus: (state) => {
      state.status = 'idle'
      state.response = null
      }
  },
  extraReducers(builder) {
    builder
      .addCase(updateOfferStep1.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateOfferStep1.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(updateOfferStep1.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const updateOfferStep1State = (state) => state.UpdateOfferStep1;
export const { resetUpdateOfferStatus } = UpdateOfferStep1Slice.actions;
export default UpdateOfferStep1Slice.reducer;
