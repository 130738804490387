import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'intern-service/intern-offer/offer-decline';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const declineOffer = createAsyncThunk(
  'declineOffer',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const DeclineOfferSlice = createSlice({
  name: 'DeclineOffer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(declineOffer.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(declineOffer.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(declineOffer.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const declineOfferState = (state) => state.DeclineOffer;
export default DeclineOfferSlice.reducer;
