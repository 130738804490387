import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/browse-intern/delete-shortlist-intern';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getShortListInternRemove = createAsyncThunk(
  'getShortListInternRemove',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetShortListInternRemoveSlice = createSlice({
  name: 'GetShortListInternRemove',
  initialState,
  reducers: {
    resetRemovedShortList : () => initialState
  },
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getShortListInternRemove.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getShortListInternRemove.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(getShortListInternRemove.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getShortListInternRemoveState = (state) => state.GetShortListInternRemove;
export const  {resetRemovedShortList} = GetShortListInternRemoveSlice.actions;
export default GetShortListInternRemoveSlice.reducer;
