import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetInternDetailsURL = 'intern-service/get-intern-by-uuid/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getInternByUuid = createAsyncThunk(
  'getInternDetails',
  async (request, { rejectWithValue }) => {
    const { uuid } = request;
    const result = await axios
      .get(GetInternDetailsURL + uuid)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternDetailsSlice = createSlice({
  name: 'GetInternDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get details of intern state handling */
      .addCase(getInternByUuid.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getInternByUuid.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(getInternByUuid.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getInternByUuidState = (state) => {
  return state.GetInternDetails;
};
export default GetInternDetailsSlice.reducer;
