import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetAlternatePOCSURL = 'host-company/admin-setting/get-alternatePoc-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getAlternatePOCS = createAsyncThunk(
  'getAlternatePOCS',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(GetAlternatePOCSURL)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetAlternatePOCSSlice = createSlice({
  name: 'GetAlternatePOCS',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get alternate pocs state handling */
      .addCase(getAlternatePOCS.pending, (state) => ({ ...state, status: 'Pending' }))
      .addCase(getAlternatePOCS.fulfilled, (state, action) => ({
        ...state,
        status: action.payload.data.isSuccess ? 'Success' : 'Failed',
        response: action.payload.data.payload,
      }))
      .addCase(getAlternatePOCS.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getAlternatePOCSState = (state) => state.GetAlternatePOCS;
export default GetAlternatePOCSSlice.reducer;
