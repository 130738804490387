export const configs = {
  REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_ENV: process.env.NODE_ENV,
  FE_BASE_URL: () => window.location.origin,
  REACT_APP_INTERN_GOOGLE_CLIENT_ID: process.env.REACT_APP_INTERN_GOOGLE_CLIENT_ID,
  REACT_APP_INTERN_GOOGLE_CLIENT_SECRET: process.env.REACT_APP_INTERN_GOOGLE_CLIENT_SECRET,
  REACT_APP_HC_GOOGLE_CLIENT_ID: process.env.REACT_APP_HC_GOOGLE_CLIENT_ID,
  REACT_APP_HC_GOOGLE_CLIENT_SECRET: process.env.REACT_APP_HC_GOOGLE_CLIENT_SECRET,
  REACT_APP_ASSET_BASE_URL: process.env.REACT_APP_ASSET_BASE_URL,
  REACT_APP_FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
};
