import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    open: false,
    severity: 'success',
    alertTitle: '',
    message: '',
  },
  reducers: {
    showSnackbar: (state, action) => {
      const { severity, alertTitle, message } = action.payload;
      state.open = true;
      state.severity = severity;
      state.alertTitle = alertTitle;
      state.message = message;
    },
    hideSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const showSnackbarState = (state) => state.Snackbar;
export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
