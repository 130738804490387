import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/browse-intern/intern/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getInternDetailsByUuid = createAsyncThunk(
  'getInternDetailsByUuid',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(url + request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternDetailsByUuidSlice = createSlice({
  name: 'GetInternDetailsByUuid',
  initialState,
  reducers: {
    resetInternDetailsByUuid: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getInternDetailsByUuid.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getInternDetailsByUuid.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
        state.isLoading = false;
      })
      .addCase(getInternDetailsByUuid.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getInternDetailsByUuidState = (state) => state.GetInternDetailsByUuid;
export const { resetInternDetailsByUuid } = GetInternDetailsByUuidSlice.actions;
export default GetInternDetailsByUuidSlice.reducer;
