import { createSlice } from '@reduxjs/toolkit';

const TokenSlice = createSlice({
  name: 'Token',
  initialState: { tokenDetails: null },
  reducers: {
    setTokenDetails: (state, action) => {
      state.tokenDetails = action.payload || null;
    },
    clearTokenDetails: (state) => {
      state.tokenDetails = null;
    },
  },
});

export const getTokenDetailsState = (state) => state.Token.tokenDetails;
export const { setTokenDetails, clearTokenDetails } = TokenSlice.actions;
export default TokenSlice.reducer;
