import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const SaveMainChallengeUrl = 'intern-service/weekly-feedback-loop/question/';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveMainChallenge = createAsyncThunk(
  'saveMainChallenge',
  async (request, { rejectWithValue }) => {
    const weeklyFeedbackId = request.weekly_feedback_id;
    delete request.weekly_feedback_id;
    const result = await axios
      .post(`${SaveMainChallengeUrl + weeklyFeedbackId}/main-challenge`, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveMainChallengeSlice = createSlice({
  name: 'SaveMainChallenge',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate save main-challenge state handling */
      .addCase(saveMainChallenge.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(saveMainChallenge.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(saveMainChallenge.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const saveMainChallengeState = (state) => state.SaveMainChallenge;
export default SaveMainChallengeSlice.reducer;
