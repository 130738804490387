import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/internship-plan/update-status/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const updateInternshipPlanStatus = createAsyncThunk(
  'updateInternshipStatus',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const UpdateInternshipPlanSlice = createSlice({
  name: 'UpdateInternshipStatus',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(updateInternshipPlanStatus.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(updateInternshipPlanStatus.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(updateInternshipPlanStatus.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const updateInternshipPlanStatusState = (state) => state.UpdateInternshipStatus;
export const { reset } = UpdateInternshipPlanSlice.actions;
export default UpdateInternshipPlanSlice.reducer;
