import { createSlice } from '@reduxjs/toolkit';

const shortlistCountSlice = createSlice({
  name: 'shortlistCount',
  initialState: null,
  reducers: {
    showShortlistCount: (state, action) => {
      return action.payload;
    },
  },
});

export const { showShortlistCount } = shortlistCountSlice.actions;

export default shortlistCountSlice.reducer;
