import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/hired-interns/weekly-feedback-loop/question/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const saveRatingAnswer = createAsyncThunk(
  'saveRatingAnswer',
  async (request, { rejectWithValue }) => {
    const internId = request.intern_id;
    const weeklyFeedbackId = request.weekly_feedback_id;

    delete request.intern_id;
    delete request.weekly_feedback_id;
    const result = await axios
      .post(`${url + internId}/${weeklyFeedbackId}/rate-your-internship-experience`, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveRatingAnswerSlice = createSlice({
  name: 'SaveRatingAnswer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate hours work asnwer  state handling */
      .addCase(saveRatingAnswer.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(saveRatingAnswer.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(saveRatingAnswer.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const saveRatingAnswerState = (state) => state.SaveRatingAnswer;
export default SaveRatingAnswerSlice.reducer;
