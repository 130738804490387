import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

let url = '/host-company/offer/save-offer-io';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
  firstStepResponse: {},
};
export const saveOfferStep1 = createAsyncThunk(
  'saveOfferStep1',
  async (request, { rejectWithValue }) => {
    let tempRequest = request;
    if (request?.isEdit) {
      url = '/host-company/offer/update-intern-offer';
      delete tempRequest?.isEdit;
    }
    const result = await axios
      .post(url, tempRequest)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveOfferStep1Slice = createSlice({
  name: 'SaveOfferStep1',
  initialState,
  reducers: {
    resetSaveOfferStatus: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },

    saveFirstStepOfferResponse: (state, action) => {
      state.firstStepResponse = action?.payload;
    },
    resetFirstStepOfferResponse: (state) => {
      state.status = 'idle';
      state.isLoading = false;
      state.error = null;
      state.response = null;
      state.firstStepResponse = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(saveOfferStep1.pending, (state) => ({
        ...state,
        status: 'Pending',
        isLoading: true,
      }))
      .addCase(saveOfferStep1.fulfilled, (state, action) => {
        if (action?.payload?.status === 409) {
          return { ...state, status: 409 };
        } else if (action?.payload?.status === 403 || !action?.payload?.data?.isSuccess) {
          return {
            ...state,
            status: 'Failed',
            response: action?.payload?.data?.message,
            isLoading: false,
          };
        } else {
          return {
            ...state,
            status: 'Success',
            response: action?.payload?.data?.payload,
            isLoading: false,
          };
        }
      })
      .addCase(saveOfferStep1.rejected, (state, action) => ({
        ...state,
        status: 'Failed',
        isLoading: false,
        response: action?.payload?.response?.data?.data?.message,
      }));
  },
});

export const saveOfferStep1State = (state) => state.SaveOfferStep1;
export const { resetSaveOfferStatus, saveFirstStepOfferResponse, resetFirstStepOfferResponse } =
  SaveOfferStep1Slice.actions;

export default SaveOfferStep1Slice.reducer;
