import { reduxStore } from '../Redux/Store';
import axios from 'axios';
import { configs } from '../Utils/Common/configs';
import { datadogLogs } from '@datadog/browser-logs';
import { v4 as uuidv4 } from 'uuid';
import { saveToken } from '../Redux/Slices/Auth/SaveToken';
import { handleLogOut } from './AxiosInterceptor';
const BASE_URL = configs.REACT_APP_BASE_URL;
const axiosObject = axios.create({
  withCredentials: true,
  mode: 'cors',
  baseURL: BASE_URL,
});

const UNAUTHORIZED_401 = 401;

const generateRequestId = () => {
  return uuidv4();
};

const logTrigger = (message, jsonAttributes, status, error = null) => {
  datadogLogs.logger.log(message, jsonAttributes, status, error);
};

// Adding X-Request-ID as a request header to track api request
axiosObject.interceptors.request.use((config) => {
  // if user is coming from magic link then token value will be stored in
  // redux, so we have to authenticate the user from that token
  const tokenValue = reduxStore.getState().savedToken.token;
  if (tokenValue) {
    config.params = {
      ...config.params,
      token: tokenValue,
    };
    // once the token is authenticated then cookies will be set
    // and we don't need to send the token as the query param any more
    reduxStore.dispatch(saveToken({ token: null }));
  }

  const requestId = generateRequestId();
  config.headers['X-Request-ID'] = requestId;
  return config;
});

axiosObject.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // If we have either 401 then taking the user to
    // /welcome page
    logTrigger(
      error?.message,
      { errorConfig: error?.config, errorCode: error?.code },
      'error',
      error,
    );
    if (error?.response?.status === UNAUTHORIZED_401) {
      sessionStorage.clear();
      localStorage.clear();
      handleLogOut({ clearStore: false });
    }
    return error?.response;
  },
  (reject) => {
    console.log(reject);
  },
);

export default axiosObject;
