import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const CheckSlugURL = 'common-services/batch/check-slug?slug=';

export const CheckSlugApi = createApi({
  reducerPath: 'checkSlugApi',
  baseQuery: customBaseQuery,
  tagTypes: ['checkSlugType'],
  endpoints: (builder) => ({
    checkSlug: builder.query({
      query: (body) => ({ url: `${CheckSlugURL}${body?.slugVal}`, method: 'GET' }),
    }),
  }),
});

export const { useCheckSlugQuery } = CheckSlugApi;

export default CheckSlugApi.reducer;
