import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const saveSocialMediaDetailsUrl = '/host-company/profile/save-social-media-info';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveSocialMediaDetails = createAsyncThunk(
  'saveSocialMediaDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(saveSocialMediaDetailsUrl, request)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);



const SaveSocialMediaDetailsSlice = createSlice({
  name: 'SaveSocialMediaDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate saving photos state handling */
      .addCase(saveSocialMediaDetails.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(saveSocialMediaDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(saveSocialMediaDetails.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSaveSocialMediaDetailsState = (state) => state.SaveSocialMediaDetails;
export default SaveSocialMediaDetailsSlice.reducer;
