import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/browse-intern/save-shortlist-intern';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getShortListInternSave = createAsyncThunk(
  'getShortListInternSave',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetShortListInternSaveSlice = createSlice({
  name: 'GetShortListInternSave',
  initialState,
  reducers: {
    resetSaveShortList: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getShortListInternSave.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getShortListInternSave.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(getShortListInternSave.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getShortListInternSaveState = (state) => state.GetShortListInternSave;
export const { resetSaveShortList } = GetShortListInternSaveSlice.actions;
export default GetShortListInternSaveSlice.reducer;
