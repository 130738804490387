import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = '/host-company/dashboard/list-offers';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  getOfferApiCallParams: {},
};
export const getOffers = createAsyncThunk(
  'getOffers',
  async (request, { rejectWithValue, dispatch }) => {
    const result = await axios
      .post(url, request)
      .then((response) => {
        dispatch(storeGetOffersApiCallParams());
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetOffersSlice = createSlice({
  name: 'GetOffers',
  initialState,
  reducers: {
    storeGetOffersApiCallParams: (state, action) => {
      state.getOfferApiCallParams = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getOffers.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getOffers.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
        state.count = action?.payload?.count;
      })
      .addCase(getOffers.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getOffersState = (state) => state.GetOffers;
export default GetOffersSlice.reducer;
export const { storeGetOffersApiCallParams } = GetOffersSlice.actions;
