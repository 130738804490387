import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const ViewDetailedWeeklyFeedbackUrl = 'intern-service/weekly-feedback-loop/view-detail/';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const viewDetailedWeeklyFeedback = createAsyncThunk(
  'viewDetailedWeeklyFeedback',
  async (request, { rejectWithValue }) => {
    const weeklyFeedbackId = request.weekly_feedback_id;
    const result = await axios
      .get(ViewDetailedWeeklyFeedbackUrl + weeklyFeedbackId)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const ViewDetailedWeeklyFeedbackSlice = createSlice({
  name: 'ViewDetailedWeeklyFeedback',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get view detail weekly feedback state handling */
      .addCase(viewDetailedWeeklyFeedback.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(viewDetailedWeeklyFeedback.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(viewDetailedWeeklyFeedback.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const viewDetailedWeeklyFeedbackState = (state) => state.ViewDetailedWeeklyFeedback;
export default ViewDetailedWeeklyFeedbackSlice.reducer;
