import axios from '../../../Axios/Axios';

const State = {};

export const getMasterServices = async (url, method = 'get', body, resultObject) => {
  if (method === 'post') {
    try {
      const response = await axios.post(url, body);
      const result = [];
      if (resultObject) {
        response?.data?.data?.payload?.results?.map((item) => {
          const keys = Object.keys(item);
          result.push({
            label: item[[keys[1]]],
            value: item[[keys[0]]],
            entity: item,
          });
        });
      } else {
        response?.data?.data?.payload?.map((item) => {
          const keys = Object.keys(item);
          result.push({
            label: item[[keys[1]]],
            value: item[[keys[0]]],
            entity: item,
          });
        });
      }
      State[url] = {};
      State[url].response = result;
      State[url].status = 'Success';
    } catch (error) {
      State[url] = {};
      State[url].status = 'Failed';
      State[url].error = error;
    }
  } else {
    try {
      const response = await axios.get(url);
      const result = [];
      if (resultObject) {
        response?.data?.data?.payload?.results?.map((item) => {
          const keys = Object.keys(item);
          result.push({
            label: item[[keys[1]]],
            value: item[[keys[0]]],
            entity: item,
            // entity: item
          });
        });
      } else {
        response?.data?.data?.payload?.map((item) => {
          const keys = Object.keys(item);
          result.push({
            label: item[[keys[1]]],
            value: item[[keys[0]]],
            entity: item,
            // entity: item
          });
        });
      }

      State[url] = {};
      State[url].response = result;
      State[url].status = 'Success';
    } catch (error) {
      State[url] = {};
      State[url].status = 'Failed';
      State[url].error = error;
    }
  }

  return State[url];
};
