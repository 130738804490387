import { createSlice } from '@reduxjs/toolkit';

const matTextAreaSlice = createSlice({
  name: 'matTextArea',
  initialState: {
    value: '',
  },
  reducers: {
    handleCommentsChange: (state, action) => {
      state.value = action.payload;
    },
    clearMatTextAreaState: (state) => {
      state.value = '';
    },
  },
});

export const matTextAreaState = (state) => state.MatTextArea;
export const { handleCommentsChange, clearMatTextAreaState } = matTextAreaSlice.actions;
export default matTextAreaSlice.reducer;
