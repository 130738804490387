import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const saveInvitedUserDetailsURL = '/host-company/invited/user';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const saveInvitedUserDetails = createAsyncThunk(
  'saveInvitedUserDetails',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.put(saveInvitedUserDetailsURL, request);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue(result?.data?.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const saveInvitedUserDetailsSlice = createSlice({
  name: 'saveInvitedUserDetails',
  initialState,

  reducers: {
    clearSaveInvitedUserDetails: () => ({
      status: 'idle',
      error: null,
      response: null,
      isLoading: false,
    }),
  },
  extraReducers: {
    [saveInvitedUserDetails.pending]: (state) => ({
      ...state,
      status: 'Pending',
      isLoading: true,
    }),
    [saveInvitedUserDetails.fulfilled]: (state, action) => ({
      ...state,
      status: 'Success',
      response: action?.payload?.payload,
      isLoading: false,
    }),
    [saveInvitedUserDetails.rejected]: (state, action) => ({
      ...state,
      isLoading: false,
      status: 'Failed',
      error: action?.payload,
    }),
  },
});

export const saveInvitedUserDetailsState = (state) => state.SaveInvitedUserDetails;
export default saveInvitedUserDetailsSlice.reducer;
export const { clearSaveInvitedUserDetails } = saveInvitedUserDetailsSlice.actions;
