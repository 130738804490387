import {  createSlice } from '@reduxjs/toolkit';
import { reduxStore } from '../../../Store';

const initialState = {
  status: '',
  unsubscribe: null,


};

const SetApplicationNextLoaderSlice = createSlice({
  name: 'SetApplicationNextLoader',
  initialState,
  reducers: {
    setLoaderStatus: (state, action) => {
        state.status = action?.payload?.status;  
    },
    setUnsubscribe: (state, action) => {
      state.unsubscribe = action.payload;
    },
  },
});
export const waitForStoreUpdate = () => {
  return new Promise((resolve) => {
    const unsubscribe = reduxStore.getState().SetApplicationNextLoaderSlice.subscribe(() => {
      const state = reduxStore.getState();
      if (!state.data.loading) {
        unsubscribe();
        resolve(state.data);
      }
    });
  });
};
export const getApplicationNextLoaderState = (state) => state.SetApplicationNextLoader;
export const { setLoaderStatus, setUnsubscribe } = SetApplicationNextLoaderSlice.actions;

export default SetApplicationNextLoaderSlice.reducer;
