import otherMediaDetailsReducer from '../Slices/Company/Profile/GetOtherMediaDetails';
import getBasicDetailsReducer from '../Slices/Company/Profile/GetBasicDetails';
import getGeneralDetailsReducer from '../Slices/Company/Profile/GetGeneralDetails';
import getAdditionalDetailsReducer from '../Slices/Company/Profile/GetAdditionalDetails';
import savePhotosReducer from '../Slices/Company/Profile/SavePhotos';
import saveVideosReducer from '../Slices/Company/Profile/SaveVideos';
import saveSocialMediaDetailsReducer from '../Slices/Company/Profile/SaveSocialMediaDetails';
import getCompanyDetailsReducer from '../Slices/Company/RetrieveDetails/GetCompanyByUuid.js';
import saveCompanyBasicInfoReducer from '../Slices/Company/SignUp/SaveBasicInfo';
import saveCompanyProfileReducer from '../Slices/Company/SignUp/SaveCompanyProfile';
import saveAboutCompanyReducer from '../Slices/Company/SignUp/SaveAboutCompany';
import getOTPProfileReferencer from '../Slices/Company/SignUp/GetOTPProfileReferencer';
import alternatePOCSReducer from '../Slices/Company/Admin/GetAlternatePOCS';
import updateAlternatePOCSReducer from '../Slices/Company/Admin/UpdateAlternatePOC';
import deleteAlternatePOCSReducer from '../Slices/Company/Admin/DeleteAlternatePOC';
import addAlternatePOCSReducer from '../Slices/Company/Admin/AddAlternatePOC';
import adminDetailsReducer from '../Slices/Company/Admin/GetAdminDetails';
import updateAdminDetailsReducer from '../Slices/Company/Admin/UpdateAdminDetails';
import saveBasicDetailsReducer from '../Slices/Company/Profile/SaveBasicDetails';
import saveGeneralDetailsReducer from '../Slices/Company/Profile/SaveGeneralDetails';
import saveAdditionalDetailsReducer from '../Slices/Company/Profile/SaveAdditionalDetails';
import getInternshipPlansReducer from '../Slices/Company/InternshipPlans/GetInternshipPlans';
import getInternshipPlanDetailsReducer from '../Slices/Company/RetrieveDetails/GetIntershipPlanById';
import getJobFunctionsReducer from '../Slices/Company/InternshipPlans/GetJobFunctions';
import updateInternshipPlanStatus from '../Slices/Company/InternshipPlans/UpdateIntershipPlanStatus';
import getOnboardingStatusReducer from '../Slices/Company/Onboarding/GetOnboardingStatus';
import getInternListReducer from '../Slices/Company/ViTalentPool/GetInternList';
import getShortListInternReducer from '../Slices/Company/ViTalentPool/GetShortListIntern';
import getShortInternListSaveReducer from '../Slices/Company/ViTalentPool/GetShortInternSave';
import getShortInternListRemoveReducer from '../Slices/Company/ViTalentPool/GetShortInternRemove';
import getInterviewCreditsReducer from '../Slices/Company/ViTalentPool/GetInterviewCredits';
import submitInterviewFeedback from '../Slices/Company/Overview/SubmitInterviewFeedback';
import getListCandidateReducer from '../Slices/Company/Dashboard/getListCandidate';
import getInterviewDetails from '../Slices/Company/Interview/GetInterviewDetails';
import getActiveInternsListReducer from '../Slices/Company/HiredInterns/GetActiveInternsList';
import getPastInternsListReducer from '../Slices/Company/HiredInterns/GetPastInternsList';
import getProjectAnswerReducer from '../Slices/Company/HiredInterns/GetProjectAnswer';
import getImproveAnswerReducer from '../Slices/Company/HiredInterns/GetImproveAnswer';
import getHoursWorkAnswerReducer from '../Slices/Company/HiredInterns/GetHoursWorkAnswer';
import getRatingAnswerReducer from '../Slices/Company/HiredInterns/GetRating';
import getCommunicationAnswerReducer from '../Slices/Company/HiredInterns/GetCommunication';
import saveProjectAnswerReducer from '../Slices/Company/HiredInterns/SaveProjectAnswer';
import saveImproveAnswerReducer from '../Slices/Company/HiredInterns/SaveImproveAnswer';
import saveHoursWorkAnswerReducer from '../Slices/Company/HiredInterns/SaveHoursWork';
import saveRatingAnswerReducer from '../Slices/Company/HiredInterns/SaveRating';
import saveCommunicationAnswerReducer from '../Slices/Company/HiredInterns/SaveCommunication';
import getWeekDetailsReducer from '../Slices/Company/HiredInterns/GetWeekDetails';
import createCustomProjectReducer from '../Slices/Company/HiredInterns/CreateCustomProject';
import getWeeklyFeedbackReducer from '../Slices/Company/HiredInterns/GetWeeklyFeedback';
import getWeeklyFeedbacksCheckInReducer from '../Slices/Company/HiredInterns/GetWeeklyFeedbacksCheckIn';
import getOfferDetailsReducer from '../Slices/Company/HiredInterns/GetOfferDetails';
import saveOfferStep1Reducer from '../Slices/Company/Offer/SaveOfferStep1';
import saveOfferStep2Reducer from '../Slices/Company/Offer/SaveOfferStep2';
import saveOfferStep3Reducer from '../Slices/Company/Offer/SaveOfferStep3';
import getOffersReducer from '../Slices/Company/Offer/GetOffers';
import updateOfferStep1Reducer from '../Slices/Company/Offer/UpdateOfferStep1';
import getInternDetailsByUuid from '../Slices/Company/ViTalentPool/GetInternDetailsByUuid';
import getInternDetailByUuidV2 from '../Slices/Company/ViTalentPool/GetInternDetailByUuidV2';
import getLoggedInUserDetailsReducer from '../Slices/Company/Rbac/GetLoggedInUserDetails';
import adminPageDetailsReducer from '../Slices/Company/Admin/AdminPageRbac';
import { GetConfirmInterviewDetailApi } from '../Slices/Company/ViTalentPool/GetConfirmInterviewDetail';
import { InternshipOpportunityApi } from '../Slices/Company/InternshipOpportunity/InternshipOpportunity';
import { GetInternAvailableApi } from '../Slices/Company/ViTalentPool/GetInternAvailablebyUuid';
import { CandidatureApi } from '../Slices/Company/Candidature/Candidature';
import { CreditsApi } from '../Slices/Company/Admin/GetHcCredits';
import shortlistTooltipReducer from '../Slices/Company/ViTalentPool/showTooltip';
import shortlistCountReducer from '../Slices/Company/ViTalentPool/shortListCount';
import saveMissingInfoOfHC from '../Slices/Company/Admin/SaveMissingDeatilsOfPoc';
import { GetInterviewTimeApi } from '../Slices/Company/Interview/GetInterviewTime';
import shortlistActionReducer from '../Slices/Company/ViTalentPool/ShortListAction';

export const companyReducers = {
  GetOtherMediaDetails: otherMediaDetailsReducer,
  SavePhotos: savePhotosReducer,
  SaveVideos: saveVideosReducer,
  SaveSocialMediaDetails: saveSocialMediaDetailsReducer,
  GetCompanyDetails: getCompanyDetailsReducer,
  SaveCompanyBasicInfo: saveCompanyBasicInfoReducer,
  SaveCompanyProfile: saveCompanyProfileReducer,
  SaveAboutCompany: saveAboutCompanyReducer,
  GetOTPProfileReferencer: getOTPProfileReferencer,
  GetAlternatePOCS: alternatePOCSReducer,
  UpdateAlternatePOC: updateAlternatePOCSReducer,
  DeleteAlternatePOC: deleteAlternatePOCSReducer,
  AddAlternatePOC: addAlternatePOCSReducer,
  GetAdminDetails: adminDetailsReducer,
  UpdateAdminDetails: updateAdminDetailsReducer,
  GetBasicDetails: getBasicDetailsReducer,
  GetGeneralDetails: getGeneralDetailsReducer,
  GetAdditionalDetails: getAdditionalDetailsReducer,
  SaveBasicDetails: saveBasicDetailsReducer,
  SaveGeneralDetails: saveGeneralDetailsReducer,
  SaveAdditionalDetails: saveAdditionalDetailsReducer,
  GetInternshipPlans: getInternshipPlansReducer,
  GetInternshipPlan: getInternshipPlanDetailsReducer,
  GetJobFunctions: getJobFunctionsReducer,
  UpdateInternshipStatus: updateInternshipPlanStatus,
  GetOnboardingStatus: getOnboardingStatusReducer,
  GetInternList: getInternListReducer,
  GetShortListIntern: getShortListInternReducer,
  GetShortListInternSave: getShortInternListSaveReducer,
  GetShortListInternRemove: getShortInternListRemoveReducer,
  GetInterviewCredits: getInterviewCreditsReducer,
  SubmitInterviewFeedback: submitInterviewFeedback,
  GetListCandidate: getListCandidateReducer,
  GetInterviewDetails: getInterviewDetails,
  GetActiveInternsList: getActiveInternsListReducer,
  GetPastInternsList: getPastInternsListReducer,
  GetProjectAnswer: getProjectAnswerReducer,
  GetImproveAnswer: getImproveAnswerReducer,
  GetHoursWorkAnswer: getHoursWorkAnswerReducer,
  GetRatingAnswer: getRatingAnswerReducer,
  GetCommunicationAnswer: getCommunicationAnswerReducer,
  SaveProjectAnswer: saveProjectAnswerReducer,
  SaveImproveAnswer: saveImproveAnswerReducer,
  SaveHoursWorkAnswer: saveHoursWorkAnswerReducer,
  SaveRatingAnswer: saveRatingAnswerReducer,
  SaveCommunicationAnswer: saveCommunicationAnswerReducer,
  CreateCustomProject: createCustomProjectReducer,
  GetWeekDetails: getWeekDetailsReducer,
  GetWeeklyFeedback: getWeeklyFeedbackReducer,
  GetWeeklyFeedbacksCheckIn: getWeeklyFeedbacksCheckInReducer,
  GetOfferDetails: getOfferDetailsReducer,
  SaveOfferStep1: saveOfferStep1Reducer,
  SaveOfferStep2: saveOfferStep2Reducer,
  SaveOfferStep3: saveOfferStep3Reducer,
  GetOffers: getOffersReducer,
  UpdateOfferStep1: updateOfferStep1Reducer,
  GetLoggedInUserDetails: getLoggedInUserDetailsReducer,
  GetInternDetailsByUuid: getInternDetailsByUuid,
  GetInternDetailsByUuidV2: getInternDetailByUuidV2,
  AdminPageDetails: adminPageDetailsReducer,
  [GetConfirmInterviewDetailApi.reducerPath]: GetConfirmInterviewDetailApi.reducer,
  [InternshipOpportunityApi.reducerPath]: InternshipOpportunityApi.reducer,
  [GetInternAvailableApi.reducerPath]: GetInternAvailableApi.reducer,
  [CandidatureApi.reducerPath]: CandidatureApi.reducer,
  [CreditsApi.reducerPath]: CreditsApi.reducer,
  [GetInterviewTimeApi.reducerPath]: GetInterviewTimeApi.reducer,
  shortlistTooltip: shortlistTooltipReducer,
  shortlistCount: shortlistCountReducer,
  saveMissingInfoOfHC: saveMissingInfoOfHC,
  shortListAction: shortlistActionReducer,
};
