import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import axios from '../../../../Axios/Axios';

const SaveBasicInfoURL = 'intern-service/register/save-basic-info';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveBasicInfo = createAsyncThunk(
  'saveBasicInfo',
  async (request, { rejectWithValue }) => {
    const formatedDate = moment(request?.intern_dob)?.format('yyyy-MM-DD');
    request.intern_dob = formatedDate;
    const result = await axios
      .post(SaveBasicInfoURL, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveInternBasicInfoSlice = createSlice({
  name: 'SaveInternBasicInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate save basic info of intern state handling */
      .addCase(saveBasicInfo.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(saveBasicInfo.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(saveBasicInfo.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getSaveInternBasicInfoState = (state) => state.SaveInternBasicInfo;
export default SaveInternBasicInfoSlice.reducer;
