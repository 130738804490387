import { internReducers } from './internReducers';
import { commonReducers } from './commonReducers';
import { companyReducers } from './companyReducers';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';

export const reducer = {
  ...internReducers,
  ...commonReducers,
  ...companyReducers,
};

const appReducer = combineReducers({ ...reducer })

const rootReducer = (state, action) => {
  if (action.type === 'clear/clearResults') {

    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root')

    state = {}
  }
  return appReducer(state, action)
}

export default rootReducer


