import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentState: '',
};

const GetOTPProfileReferencerSlice = createSlice({
  name: 'GetOTPProfileRefencer',
  initialState,
  reducers: {
    setCurrentStateReferencerOTPProfile: (state, action) => {
      state.currentState = action.payload.currentState;
    },
  },
});

export const getOTPProfileReferencer = (state) => state.GetOTPProfileReferencer;
export const { setCurrentStateReferencerOTPProfile } = GetOTPProfileReferencerSlice.actions;
export default GetOTPProfileReferencerSlice.reducer;
