import {  createSlice } from '@reduxjs/toolkit';

const initialState = {
  mode: '',
};
const SetPastExpModeSlice = createSlice({
  name: 'SetPastExpMode',
  initialState,
  reducers: {
    setWorkMode: (state, action) => {
        state.mode = action?.payload?.mode;  
    },
   
  },
});

export const getPastExpMode = (state) => state.SetPastExpMode;
export const { setWorkMode } = SetPastExpModeSlice.actions;

export default SetPastExpModeSlice.reducer;
