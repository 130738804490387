import { getMasterServices } from './MasterGetService';
import { genericApiCall } from '..';
import { objectToQueryString } from '../../../Utils/Common/utils';

export const getJobFunctionList = (body) => {
  const queryString = objectToQueryString(body);
  const URL = queryString
    ? `common-services/job-functions?${queryString}`
    : 'common-services/job-functions';
  getMasterServices(URL, 'get', {}, false);
};

export const getBrowseCandidate = (body) =>
  genericApiCall('host-company/browse-intern/get-list', 'post', body, false);

export const getCareerFields = () =>
  getMasterServices('common-services/career-field/get-list', 'get', null, null);
export const getProjectList = (body) => {
  let url = 'common-services/company-project/list';
  if (body) {
    const filters = body?.filter;
    url += '?';
    Object.keys(filters).forEach((key, index) => {
      url += index > 0 ? `&${key}=${filters[key]}` : `${key}=${filters[key]}`;
    });
  }

  return getMasterServices(url, 'get', body, null);
};
export const getCareerSkill = () =>
  getMasterServices('common-services/career-skill/list', 'get', null, false);
export const getContextUser = () => genericApiCall('api/check-token', 'get', null, false);
export const logout = () => genericApiCall('api/logout', 'get', null, false);
export const uploadFile = (request) =>
  genericApiCall('common-services/upload-file', 'post', request, null);
export const getPublicProfileDetail = (request) =>
  genericApiCall(`host-company/public/profile/${request}`, 'get', null, false);

export const updateLastActiveTimeApiCall = (body) =>
  genericApiCall('common-services/user/last-seen', 'patch', body, false);
