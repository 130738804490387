import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/hired-interns/weekly-feedback-loop/question/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const saveCommunicationAnswer = createAsyncThunk(
  'saveCommunicationAnswer',
  async (request, { rejectWithValue }) => {
    const internId = request.intern_id;
    const weeklyFeedbackId = request.weekly_feedback_id;

    delete request.intern_id;
    delete request.weekly_feedback_id;
    const result = await axios
      .post(`${url + internId}/${weeklyFeedbackId}/communication-with-supervisor`, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveCommunicationAnswerSlice = createSlice({
  name: 'SaveCommunicationAnswer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate hours work asnwer  state handling */
      .addCase(saveCommunicationAnswer.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(saveCommunicationAnswer.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(saveCommunicationAnswer.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const saveCommunicationAnswerState = (state) => state.SaveCommunicationAnswer;
export default SaveCommunicationAnswerSlice.reducer;
