import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const SaveBasicInfoURL = 'host-company/save-basic-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveBasicInfo = createAsyncThunk(
  'saveBasicInfo',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(SaveBasicInfoURL, request)
      .then((response) => {
        if (response.data?.data?.isSuccess) {
          return response.data;
        }
        throw rejectWithValue(response?.data);
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const SaveCompanyBasicInfoSlice = createSlice({
  name: 'SaveCompanyBasicInfo',
  initialState,
  reducers: {
    setSaveCompanyStatus: (state, action) => {
      state.status = action.payload.status;
    },
  },
  extraReducers(builder) {
    builder
      /* generate save basic info of intern state handling */
      .addCase(saveBasicInfo.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(saveBasicInfo.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(saveBasicInfo.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action.payload;
      });
  },
});

export const getSaveCompanyBasicInfoState = (state) => state.SaveCompanyBasicInfo;
export const { setSaveCompanyStatus } = SaveCompanyBasicInfoSlice.actions;
export default SaveCompanyBasicInfoSlice.reducer;
