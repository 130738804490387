import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const updateAdminDetailsUrl = '/host-company/admin-setting/user/profile';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const updateAdminDetails = createAsyncThunk(
  'updateAdminDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(updateAdminDetailsUrl, request)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const UpdateAdminDetailsSlice = createSlice({
  name: 'UpdateAdminDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update admin details state handling */
      .addCase(updateAdminDetails.pending, (state) => ({
        ...state,
        status: 'Pending',
        isLoading: true,
      }))
      .addCase(updateAdminDetails.fulfilled, (state, action) => ({
        ...state,
        isLoading: false,
        status: action?.payload?.isSuccess ? 'Success' : 'Failed',
        response: action?.payload?.payload,
        message: !action?.payload?.isSuccess
          ? action.payload?.payload?.error?.description || action?.payload?.message
          : '',
      }))
      .addCase(updateAdminDetails.rejected, (state) => ({
        ...state,
        status: 'Failed',
        isLoading: false,
      }));
  },
});

export const getUpdateAdminDetailsState = (state) => state.UpdateAdminDetails;
export default UpdateAdminDetailsSlice.reducer;
