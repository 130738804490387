import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';
import { objectToQueryString } from '../../../../Utils/Common/utils';

const url = 'host-company/browse/candidates';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getInternList = createAsyncThunk(
  'getInternList',
  async (request, { rejectWithValue }) => {
    let queryString = objectToQueryString(request);
    let updatedUrl = `${url}?${queryString}`;

    const result = await axios
      .get(updatedUrl)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternListSlice = createSlice({
  name: 'GetInternList',
  initialState,
  reducers: {
    resetInternListSlice: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getInternList.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getInternList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
        state.isLoading = false;
      })
      .addCase(getInternList.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getInternListState = (state) => state.GetInternList;
export const { resetInternListSlice } = GetInternListSlice.actions;
export default GetInternListSlice.reducer;
