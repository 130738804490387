import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';

const ShortListInternRemoveURL = 'host-company/browse-intern/delete-shortlist-intern';

export const ShortListInternRemoveApi = createApi({
  reducerPath: 'shortListInternRemoveApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['InternShortListRemove'],
  endpoints: (builder) => ({
    removeShortListIntern: builder.mutation({
      query: (body) => ({ url: ShortListInternRemoveURL, method: 'POST', body }),
    }),
  }),
});

export const { useRemoveShortListInternMutation } = ShortListInternRemoveApi;

export default ShortListInternRemoveApi.reducer;
