import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/hired-interns/weekly-feedback-loop/question/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getHoursWorkAnswer = createAsyncThunk(
  'getHoursWorkAnswer',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(`${url + request.intern_id}/${request.weekly_feedback_id}/hour-intern-work-this-week`)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetHoursWorkAnswerSlice = createSlice({
  name: 'GetHoursWorkAnswer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate hours work asnwer  state handling */
      .addCase(getHoursWorkAnswer.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getHoursWorkAnswer.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(getHoursWorkAnswer.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getHoursWorkAnswerState = (state) => state.GetHoursWorkAnswer;
export default GetHoursWorkAnswerSlice.reducer;
