import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetOfferDashboardURL = 'intern-service/intern-dashboard/get-offer-recieved';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getOfferDashboard = createAsyncThunk(
  'getOfferDashboard',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(GetOfferDashboardURL)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetOfferDashboardSlice = createSlice({
  name: 'GetOfferDashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* get offer details state handling */

      .addCase(getOfferDashboard.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getOfferDashboard.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getOfferDashboard.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getOfferDashboardState = (state) => state.GetOfferDashboard;
export default GetOfferDashboardSlice.reducer;
