import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const addAlternatePOCUrl = '/host-company/admin-setting/save-alternate-poc-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const addAlternatePOC = createAsyncThunk(
  'addAlternatePOC',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(addAlternatePOCUrl, request)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const AddAlternatePOCSlice = createSlice({
  name: 'AddAlternatePOC',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate add poc state handling */
      .addCase(addAlternatePOC.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(addAlternatePOC.fulfilled, (state, action) => ({
        ...state,
        status: action?.payload?.isSuccess ? 'Success' : 'Failed',
        response: action?.payload?.payload,
        message: !action.payload.isSuccess ? action.payload.message : '',
      }))
      .addCase(addAlternatePOC.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getAddAlternatePOCState = (state) => state.AddAlternatePOC;
export default AddAlternatePOCSlice.reducer;
