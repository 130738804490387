import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const saveVideosUrl = '/host-company/profile/save-videos';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveVideos = createAsyncThunk('saveVideos', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(saveVideosUrl, request)
    .then((response) => response?.data?.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});



const SaveVideosSlice = createSlice({
  name: 'SaveVideos',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate saving videos state handling */
      .addCase(saveVideos.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(saveVideos.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(saveVideos.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSaveVideosState = (state) => state.SaveVideos;
export default SaveVideosSlice.reducer;
