import axios from '../../Axios/Axios';

const State = {};
export const genericApiCall = async (url, method = 'get', request) => {
  State[url] = {};
  if (method === 'post') {
    try {
      await axios
        .post(url, request)
        .then((response) => {
          State[url].response = response;
          State[url].status = 'Success';
        })
        .catch((error) => {
          throw error;
        });
      return State[url].response;
    } catch (error) {
      State[url].status = 'Failed';
      State[url].error = error;
    }
  } else if (method === 'delete') {
    try {
      await axios
        .delete(url, request)
        .then((response) => {
          State[url].response = response;
          State[url].status = 'Success';
        })
        .catch((error) => {
          throw error;
        });
      return State[url].response;
    } catch (error) {
      State[url].status = 'Failed';
      State[url].error = error;
    }
  } else if (method === 'put') {
    try {
      await axios
        .put(url, request)
        .then((response) => {
          State[url].response = response;
          State[url].status = 'Success';
        })
        .catch((error) => {
          throw error;
        });
      return State[url].response;
    } catch (error) {
      State[url].status = 'Failed';
      State[url].error = error;
    }
  } else if (method === 'patch') {
    try {
      await axios
        .patch(url, request)
        .then((response) => {
          State[url].response = response;
          State[url].status = 'Success';
        })
        .catch((error) => {
          throw error;
        });
      return State[url].response;
    } catch (error) {
      State[url].status = 'Failed';
      State[url].error = error;
    }
  } else {
    try {
      await axios
        .get(url, request)
        .then((response) => {
          State[url].response = response;
          State[url].status = 'Success';
        })
        .catch((error) => {
          throw error;
        });
      return State[url].response;
    } catch (error) {
      State[url].status = 'Failed';
      State[url].error = error;
    }
  }
  return State[url];
};
