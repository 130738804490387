import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const CheckEmailURL = '/common-services/validate/v2/email';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  email: '',
};

/* structure of request params to be
@requestParams = 
{
    'email':'testuser6@gmail.com', -> User who is signin-up or logging in
    'user_type':'INTERN' -> For intern type else 'COMPANY'
    'source':'OTP'
 } 
 */

export const checkEmail = createAsyncThunk(
  'checkEmail',
  async (requestParams, { rejectWithValue }) => {
    const result = await axios
      .get(CheckEmailURL, { params: requestParams })
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const CheckEmailSlice = createSlice({
  name: 'CheckEmail',
  initialState,
  reducers: {
    resetEmailStatus: (state, action) => {
      (state.status = action.payload.status), (state.response = action.payload.response || {});
    },
  },
  extraReducers(builder) {
    builder
      /* generate check email state handling */
      .addCase(checkEmail.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(checkEmail.fulfilled, (state, action) => {
        state.status = action.payload?.data?.isSuccess ? 'Success' : 'Failed';
        state.response = action.payload;
      })
      .addCase(checkEmail.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action.payload.response;
      });
  },
});

export const getCheckEmailState = (state) => state.CheckEmail;
export const { resetEmailStatus } = CheckEmailSlice.actions;

export default CheckEmailSlice.reducer;
