import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetInternBasicInfoURL = 'intern-service/intern-profile/basic-info/get';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getInternBasicInfo = createAsyncThunk(
  'getInternBasicInfo',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(GetInternBasicInfoURL)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetInternBasicInfoSlice = createSlice({
  name: 'GetInternBasicInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get details of intern state handling */
      .addCase(getInternBasicInfo.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternBasicInfo.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getInternBasicInfo.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternBasicInfoState = (state) => state.GetInternBasicInfo;
export default GetInternBasicInfoSlice.reducer;
