import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const updateAlternatePOCUrl = '/host-company/admin-setting/update-alternatePoc-detail';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const updateAlternatePOC = createAsyncThunk(
  'updateAlternatePOC',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(updateAlternatePOCUrl, request)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const UpdateAlternatePOCSlice = createSlice({
  name: 'UpdateAlternatePOC',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate update poc state handling */
      .addCase(updateAlternatePOC.pending, (state) => ({ ...state, status: 'Pending' }))
      .addCase(updateAlternatePOC.fulfilled, (state, action) => ({
        ...state,
        status: action?.payload?.isSuccess ? 'Success' : 'Failed',
        response: action?.payload?.payload,
        message: !action.payload.isSuccess
          ? action.payload?.payload?.error?.description || action.payload.message
          : '',
      }))
      .addCase(updateAlternatePOC.rejected, (state) => ({ ...state, status: 'Failed' }));
  },
});

export const getUpdateAlternatePOCState = (state) => state.UpdateAlternatePOC;
export default UpdateAlternatePOCSlice.reducer;
