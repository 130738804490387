import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const SaveRatingUrl = 'intern-service/weekly-feedback-loop/question/';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveRating = createAsyncThunk('saveRating', async (request, { rejectWithValue }) => {
  const weeklyFeedbackId = request.weekly_feedback_id;
  delete request.weekly_feedback_id;
  const result = await axios
    .post(`${SaveRatingUrl + weeklyFeedbackId}/rate-your-internship-experience`, request)
    .then((response) => response.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const SaveRatingSlice = createSlice({
  name: 'SaveRating',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate save rating state handling */
      .addCase(saveRating.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(saveRating.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(saveRating.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const saveRatingState = (state) => state.SaveRating;
export default SaveRatingSlice.reducer;
