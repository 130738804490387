import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const ViewDetailedWeeklyCheckInFeedbackUrl = 'intern-service/weekly-feedback-loop/view-detail/';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const viewDetailedWeeklyCheckInFeedback = createAsyncThunk(
  'viewDetailedWeeklyCheckInFeedback',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(
        `${ViewDetailedWeeklyCheckInFeedbackUrl + request.weekly_feedback_id}?question_section=${
          request.question_section
        }`
      )
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const ViewDetailedWeeklyCheckInFeedbackSlice = createSlice({
  name: 'ViewDetailedWeeklyCheckInFeedback',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get view detail weekly check-in feedback state handling */
      .addCase(viewDetailedWeeklyCheckInFeedback.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(viewDetailedWeeklyCheckInFeedback.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(viewDetailedWeeklyCheckInFeedback.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const viewDetailedWeeklyCheckInFeedbackState = (state) =>
  state.ViewDetailedWeeklyCheckInFeedback;
export default ViewDetailedWeeklyCheckInFeedbackSlice.reducer;
