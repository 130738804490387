import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'intern-service/intern-dashboard/basic-details';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getInternBasicDetailInfo = createAsyncThunk(
  'getInternBasicDetailInfo',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternBasicDetailInfoSlice = createSlice({
  name: 'GetInternBasicDetailInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* get details of intern state handling */

      .addCase(getInternBasicDetailInfo.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternBasicDetailInfo.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getInternBasicDetailInfo.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternBasicDetailInfoState = (state) => state.GetInternBasicDetailInfo;
export default GetInternBasicDetailInfoSlice.reducer;
