import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../Axios/AxiosBaseQuery';

export const GetFeatureFlagApi = createApi({
  reducerPath: 'featureFlagApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['FeatureFlag'],
  endpoints: (builder) => ({
    getFeatureFlag: builder.query({
      query: (featureName) => {
        const url = `common-services/feature?name=${featureName}`;
        return { url, method: 'GET' };
      },
    }),
    getCalendarFeatureFlag: builder.query({
      query: ({ userId }) => {
        const url = `common-services/feature?name=GOOGLE_CAL&user_id=${userId}`;
        return { url, method: 'GET' };
      },
    }),
  }),
});

export const { useGetFeatureFlagQuery, useGetCalendarFeatureFlagQuery } = GetFeatureFlagApi;

export default GetFeatureFlagApi.reducer;
