import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';
import { showErrorMessage } from '../../Common/Notification';

const SubmitInterviewFeedbackURL = 'host-company/interview-feedback/create';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const submitInterviewFeedback = createAsyncThunk(
  'submitInterviewFeedback',
  async (request, { rejectWithValue, dispatch }) => {
    const result = await axios
      .post(SubmitInterviewFeedbackURL, request)
      .then((response) => response.data)
      .catch((error) => {
        if (error?.response?.data?.data?.message) {
          dispatch(
            showErrorMessage({
              title: error?.response?.data?.data?.message,
            })
          );
        }
        throw rejectWithValue(error);
      });
    return result;
  }
);

const SubmitInterviewFeedbackSlice = createSlice({
  name: 'SubmitInterviewFeedback',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(submitInterviewFeedback.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(submitInterviewFeedback.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.response;
      })
      .addCase(submitInterviewFeedback.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action?.payload?.response;
      });
  },
});

export const submitInterviewFeedbackState = (state) => state.SubmitInterviewFeedback;
export const { reset } = SubmitInterviewFeedbackSlice.actions;
export default SubmitInterviewFeedbackSlice.reducer;
