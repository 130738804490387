import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const upcomingInternshipUrl = 'intern-service/intern-offer/internships/upcoming';
const ongoingInternshipUrl = 'intern-service/intern-offer/internships/ongoing';
const completedInternshipUrl = 'intern-service/intern-offer/internships/complete';

export const InternshipManagementApi = createApi({
  reducerPath: 'internshipManagementApi',
  baseQuery: customBaseQuery,
  tagTypes: ['internDetails'],
  endpoints: (builder) => ({
    upcomingInternship: builder.query({
      query: () => {
        const url = upcomingInternshipUrl;
        return { url, method: 'GET', body: null };
      },
    }),
    ongoingInternship: builder.query({
      query: () => {
        const url = ongoingInternshipUrl;
        return { url, method: 'GET', body: null };
      },
    }),
    completedInternship: builder.query({
      query: () => {
        const url = completedInternshipUrl;
        return { url, method: 'GET', body: null };
      },
    }),
  }),
});

export const {
  useUpcomingInternshipQuery,
  useCompletedInternshipQuery,
  useOngoingInternshipQuery,
} = InternshipManagementApi;
export default InternshipManagementApi.reducer;
