import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/browse-intern/intern/available/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getInternDetailsByUuidV2 = createAsyncThunk(
  'getInternDetailsByUuidV2',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(url + request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternDetailsByUuidV2Slice = createSlice({
  name: 'GetInternDetailsByUuidV2',
  initialState,
  reducers: {
    resetInternDetailsByUuidV2: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getInternDetailsByUuidV2.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternDetailsByUuidV2.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getInternDetailsByUuidV2.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternDetailsByUuidV2State = (state) => state.GetInternDetailsByUuidV2;
export const { resetInternDetailsByUuidV2 } = GetInternDetailsByUuidV2Slice.actions;
export default GetInternDetailsByUuidV2Slice.reducer;
