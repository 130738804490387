import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/browse-intern/get-shortlist-interns';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getShortListIntern = createAsyncThunk(
  'getShortListIntern',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetShortListInternSlice = createSlice({
  name: 'GetShortListIntern',
  initialState,
  reducers: {
    resetShortListSlice: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getShortListIntern.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(getShortListIntern.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
        state.isLoading = false;
      })
      .addCase(getShortListIntern.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const getShortListInternState = (state) => state.GetShortListIntern;
export const { resetShortListSlice } = GetShortListInternSlice.actions;
export default GetShortListInternSlice.reducer;
