import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const OTPGenerateURL = 'common-services/otp/generate';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/* structure of request to be
@request = 
{
    'email':'testuser6@gmail.com'
 } 
 */

export const generateOTP = createAsyncThunk('generateOTP', async (request, { rejectWithValue }) => {
  const result = await axios
    .get(OTPGenerateURL, request)
    .then((response) => response.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const GenerateOTPSlice = createSlice({
  name: 'GenerateOTP',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate otp state handling */
      .addCase(generateOTP.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(generateOTP.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(generateOTP.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getGenerateOTPState = (state) => state.GenerateOTP;
export default GenerateOTPSlice.reducer;
