import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = '/host-company/offer/v2/save-offer-supervisor ';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
  selectedInterviewerId: null,
  secondSupervisorId: null,
  supervisor1_linkedIn: '',
  supervisor2_linkedIn: '',
  offerDetailsResponse: {},
};

export const saveOfferStep2 = createAsyncThunk(
  'saveOfferStep2',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveOfferStep2Slice = createSlice({
  name: 'SaveOfferStep2',
  initialState,
  reducers: {
    resetSaveOfferStatus: (state) => ({
      ...state,
      status: 'idle',
      error: null,
      response: null,
      isLoading: false,
    }),
    saveStepTwoDetails: (state, action) => ({
      ...state,
      selectedInterviewerId: action?.payload?.selectedInterviewerId,
      secondSupervisorId: action?.payload?.secondSupervisorId,
      supervisor1_linkedIn: action?.payload?.supervisor1,
      supervisor2_linkedIn: action?.payload?.supervisor2,
    }),
    clearStepTwoDetails: (state) => ({
      ...state,
      selectedInterviewerId: null,
      secondSupervisorId: null,
      supervisor1_linkedIn: '',
      supervisor2_linkedIn: '',
    }),
    saveOfferDetailsResponse: (state, action) => ({
      ...state,
      offerDetailsResponse: action?.payload,
    }),
    clearStateValues: (state) => ({
      ...state,
      status: 'idle',
      error: null,
      response: null,
      isLoading: false,
      selectedInterviewerId: null,
      secondSupervisorId: null,
      supervisor1_linkedIn: '',
      supervisor2_linkedIn: '',
      offerDetailsResponse: {},
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(saveOfferStep2.pending, (state) => ({
        ...state,
        status: 'Pending',
        isLoading: true,
      }))
      .addCase(saveOfferStep2.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action?.payload?.payload,
        isLoading: false,
      }))
      .addCase(saveOfferStep2.rejected, (state, action) => ({
        ...state,
        status: 'Failed',
        response: action?.payload?.response?.status === 500 ? {} : action?.payload?.response,
        isLoading: false,
      }));
  },
});

export const saveOfferStep2State = (state) => state.SaveOfferStep2;
export const {
  resetSaveOfferStatus,
  saveStepTwoDetails,
  clearStepTwoDetails,
  saveOfferDetailsResponse,
  clearStateValues
} = SaveOfferStep2Slice.actions;

export default SaveOfferStep2Slice.reducer;
