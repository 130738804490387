import getInternDetails from '../Slices/InternExperience/RetrieveDetails/GetInternByUuid';
import getInternDetailsForReg from '../Slices/InternExperience/RetrieveDetails/GetInternByUuidForReg';
import getLoginInternDetailsReducer from '../Slices/InternExperience/RetrieveDetails/GetLoginInternDetails';
import getInternListReducer from '../Slices/InternExperience/RetrieveDetails/GetInternList';
import saveInternBasicInfoReducer from '../Slices/InternExperience/SignUp/SaveBasicInfo';
import saveBatchDataReducer from '../Slices/InternExperience/SignUp/SaveBatchData';
import saveInternshipPrefReducer from '../Slices/InternExperience/SignUp/SaveInternshipPreference';
import saveUniversityDetailsReducer from '../Slices/InternExperience/SignUp/SaveUniversityDetails';
import getBatchListReducer from '../Slices/InternExperience/RetrieveDetails/GetBatchList';
import saveAdditionalInfoReducer from '../Slices/InternExperience/SignUp/SaveAdditionalInfo';
import getOnboardingStatusReducer from '../Slices/InternExperience/Onboarding/GetOnboardingStatus';
import getInternBasicInfoReducer from '../Slices/InternExperience/Profile/GetBasicInfo';
import createVideoAnswer from '../Slices/InternExperience/Profile/CreateVideoAnswer';
import { GetProfilePictureApi } from '../Slices/InternExperience/Profile/GetInternProfilePicture';
import getInternBasicDetailInfo from '../Slices/InternExperience/Dashboard/GetInternBasicDetailInfo';
import getProfileCompletion from '../Slices/InternExperience/Dashboard/GetInternProfileCompletion';
import getInternApplicationDetail from '../Slices/InternExperience/Dashboard/GetInternshipApplicationDetails';
import createPayment from '../Slices/InternExperience/Application/CreatePayment';
import successPageData from '../Slices/InternExperience/Application/SuccessPageData';
import createPayment3DSecure from '../Slices/InternExperience/Application/CreatePayment3DSecure';
import updateAccountDetailsReducer from '../Slices/InternExperience/Settings/UpdateAccountDetails';
import deactivateAccountReducer from '../Slices/InternExperience/Settings/DeactivateAccount';
import getInternOfferDetailsReducer from '../Slices/InternExperience/InternshipManagement/GetInternOfferDetails';
import getActiveHoursReducer from '../Slices/InternExperience/InternshipManagement/GetActiveHours';
import getMainChallengeReducer from '../Slices/InternExperience/InternshipManagement/GetMainChallenge';
import getRatingReducer from '../Slices/InternExperience/InternshipManagement/GetRating';
import getCommunicationWithSupervisorReducer from '../Slices/InternExperience/InternshipManagement/GetCommunicationWithSupervisor';
import getMainProjectsReducer from '../Slices/InternExperience/InternshipManagement/GetMainProjects';
import getNewSkillsReducer from '../Slices/InternExperience/InternshipManagement/GetNewSkills';
import saveActiveHoursReducer from '../Slices/InternExperience/InternshipManagement/SaveActiveHours';
import saveRatingReducer from '../Slices/InternExperience/InternshipManagement/SaveRating';
import saveMainChallengeReducer from '../Slices/InternExperience/InternshipManagement/SaveMainChallenge';
import saveCommunicationWithSupervisorReducer from '../Slices/InternExperience/InternshipManagement/SaveCommunicationWithSupervisor';
import saveMainProjectsReducer from '../Slices/InternExperience/InternshipManagement/SaveMainProjects';
import saveNewSkillsReducer from '../Slices/InternExperience/InternshipManagement/SaveNewSkills';
import submitWeeklyFeedbackReducer from '../Slices/InternExperience/InternshipManagement/SubmitWeeklyFeedback';
import createCustomProjectReducer from '../Slices/InternExperience/InternshipManagement/CreateCustomProject';
import viewDetailedWeeklyFeedbackReducer from '../Slices/InternExperience/InternshipManagement/ViewDetailWeeklyFeedback';
import viewDetailedWeeklyCheckInFeedbackReducer from '../Slices/InternExperience/InternshipManagement/ViewDetailWeeklyCheckInFeedback';
import declineOfferReducer from '../Slices/InternExperience/Offer/updateDeclineReasons';
import interviewFeedbackIsAttend from '../Slices/Common/Feedback/InterviewFeedbackIsAttend';
import getOfferDashboardReducer from '../Slices/InternExperience/Dashboard/GetOfferDashboard';
import acceptB2B from '../Slices/InternExperience/Offer/AcceptB2B';

import setApplicationNextLoaderReducer from '../Slices/InternExperience/Application/SetApplicationNextLoader';
import setPastExpModeReducer from '../Slices/InternExperience/Application/SetPastExpMode';
import setApplicationFeedbackReducer from '../Slices/InternExperience/Application/SetApplicationFeedback';

import getInternBatchDetailsReducer from '../Slices/InternExperience/Application/GetInternBatchDetails';
import { GetPersonalInformationApi } from '../Slices/InternExperience/Application/GetPersonalInformation';
import { GetSkillsAndGoalsApi } from '../Slices/InternExperience/Application/GetSkillsAndGoals';
import { GetEducationApi } from '../Slices/InternExperience/Application/GetEducation';
import { GetUniversityApi } from '../Slices/InternExperience/Application/GetUniversity';
import { GetPastExpApi } from '../Slices/InternExperience/Application/GetPastExp';
import { GetAdditionalQuestionApi } from '../Slices/InternExperience/Application/GetAdditionalQuestion';
import { GetApplicationShortApi } from '../Slices/InternExperience/Application/GetApplicationShort';
import { GetPortfolioApi } from '../Slices/InternExperience/Application/GetPortfolio';
import { GetInternListApi } from '../Slices/Company/ViTalentPool/GetInternListNew';
import { GetCalendarListApi } from '../Slices/Auth/CalendarList';
import { LoadLocalesApi } from '../Slices/Common/LoadLocalesApi';
import { ShortListInternSaveApi } from '../Slices/Company/ViTalentPool/ShortListInternSave';
import { ShortListInternRemoveApi } from '../Slices/Company/ViTalentPool/ShortListInternRemove';
import { GetShortListInternListApi } from '../Slices/Company/ViTalentPool/GetShortListInternNew';
import { GetInternInterviewTimeApi } from '../../Intern/Pages/Interview/GetInterviewTime';
import { DashboardApi } from '../Slices/InternExperience/Dashboard/Dashboard';
import { InternCandidatureApi } from '../Slices/InternExperience/InternCandidature/InternCandidature';
import { CheckSlugApi } from '../Slices/InternExperience/RetrieveDetails/CheckSlug';
import { InterviewTimeSlotsApi } from '../Slices/InternExperience/Application/InterviewTimeSlots';
import SavePartnerDetailReducer from '../Slices/InternExperience/RetrieveDetails/SavePartnerDetails';
import SaveBatchDetailReducer from '../Slices/InternExperience/RetrieveDetails/SaveBatchDetails';
import { InternDetailsApi } from '../Slices/Company/ViTalentPool/InternDetails';
import { DashboardApi as DashboardApiCompany } from '../Slices/Company/Dashboard/dashboard';
import { ExpressInterestApi } from '../Slices/Common/ExpressInterest';
import { InternshipManagementApi } from '../Slices/InternExperience/InternshipManagement/InternshipManagement';
import { UpdateProfilePicApi } from '../Slices/Company/Profile/UpdateProfilePic';

export const internReducers = {
  GetInternDetails: getInternDetails,
  GetInternDetailsForReg: getInternDetailsForReg,
  GetLoginInternDetails: getLoginInternDetailsReducer,
  GetInternList: getInternListReducer,
  [GetInternListApi.reducerPath]: GetInternListApi.reducer,
  [ShortListInternSaveApi.reducerPath]: ShortListInternSaveApi.reducer,
  [ShortListInternRemoveApi.reducerPath]: ShortListInternRemoveApi.reducer,
  [GetShortListInternListApi.reducerPath]: GetShortListInternListApi.reducer,
  SaveInternBasicInfo: saveInternBasicInfoReducer,
  SaveBatchData: saveBatchDataReducer,
  SaveInternshipPreference: saveInternshipPrefReducer,
  SaveInternUniversityDetails: saveUniversityDetailsReducer,
  GetBatchList: getBatchListReducer,
  SaveAdditionalInfo: saveAdditionalInfoReducer,
  GetInternBasicInfo: getInternBasicInfoReducer,
  GetOnboardingStatus: getOnboardingStatusReducer,
  CreateVideoAnswers: createVideoAnswer,
  [GetProfilePictureApi.reducerPath]: GetProfilePictureApi.reducer,
  GetInternBasicDetailInfo: getInternBasicDetailInfo,
  GetProfileCompletion: getProfileCompletion,
  GetInternApplicationDetail: getInternApplicationDetail,
  CreatePayment: createPayment,
  SuccessPageData: successPageData,
  CreatePayment3DSecure: createPayment3DSecure,
  UpdateAccountDetails: updateAccountDetailsReducer,
  DeactivateAccount: deactivateAccountReducer,
  GetInternOfferDetails: getInternOfferDetailsReducer,
  GetRating: getRatingReducer,
  GetMainChallenge: getMainChallengeReducer,
  GetCommunicationWithSupervisor: getCommunicationWithSupervisorReducer,
  GetActiveHours: getActiveHoursReducer,
  GetMainProjects: getMainProjectsReducer,
  GetNewSkills: getNewSkillsReducer,
  SaveRating: saveRatingReducer,
  SaveMainChallenge: saveMainChallengeReducer,
  SaveCommunicationWithSupervisor: saveCommunicationWithSupervisorReducer,
  SaveActiveHours: saveActiveHoursReducer,
  SaveMainProjects: saveMainProjectsReducer,
  SaveNewSkills: saveNewSkillsReducer,
  SubmitWeeklyFeedback: submitWeeklyFeedbackReducer,
  CreateCustomProject: createCustomProjectReducer,
  ViewDetailedWeeklyFeedback: viewDetailedWeeklyFeedbackReducer,
  ViewDetailedWeeklyCheckInFeedback: viewDetailedWeeklyCheckInFeedbackReducer,
  DeclineOffer: declineOfferReducer,
  InterviewFeedbackIsAttend: interviewFeedbackIsAttend,
  GetOfferDashboard: getOfferDashboardReducer,
  AcceptB2B: acceptB2B,
  SetApplicationNextLoader: setApplicationNextLoaderReducer,
  SetPastExpMode: setPastExpModeReducer,
  GetInternBatchDetails: getInternBatchDetailsReducer,
  SetApplicationFeedback: setApplicationFeedbackReducer,
  [GetPersonalInformationApi.reducerPath]: GetPersonalInformationApi.reducer,
  [GetSkillsAndGoalsApi.reducerPath]: GetSkillsAndGoalsApi.reducer,
  [GetEducationApi.reducerPath]: GetEducationApi.reducer,
  [GetUniversityApi.reducerPath]: GetUniversityApi.reducer,
  [GetPastExpApi.reducerPath]: GetPastExpApi.reducer,
  [GetAdditionalQuestionApi.reducerPath]: GetAdditionalQuestionApi.reducer,
  [GetApplicationShortApi.reducerPath]: GetApplicationShortApi.reducer,
  [GetPortfolioApi.reducerPath]: GetPortfolioApi.reducer,
  [LoadLocalesApi.reducerPath]: LoadLocalesApi.reducer,
  [GetCalendarListApi.reducerPath]: GetCalendarListApi.reducer,
  [GetInternInterviewTimeApi.reducerPath]: GetInternInterviewTimeApi.reducer,
  [DashboardApi.reducerPath]: DashboardApi.reducer,
  [InternCandidatureApi.reducerPath]: InternCandidatureApi.reducer,
  [CheckSlugApi.reducerPath]: CheckSlugApi.reducer,
  [InterviewTimeSlotsApi.reducerPath]: InterviewTimeSlotsApi.reducer,
  [InternDetailsApi.reducerPath]: InternDetailsApi.reducer,
  [DashboardApiCompany.reducerPath]: DashboardApiCompany.reducer,
  [ExpressInterestApi.reducerPath]: ExpressInterestApi.reducer,
  [UpdateProfilePicApi.reducerPath]: UpdateProfilePicApi.reducer,
  [InternshipManagementApi.reducerPath]: InternshipManagementApi.reducer,
  partnerDetails: SavePartnerDetailReducer,
  batchDetails: SaveBatchDetailReducer,
};
