import { createSlice } from '@reduxjs/toolkit';

const SaveBatchDetailSlice = createSlice({
  name: 'batchDetail',
  initialState: {},
  reducers: {
    saveBatchDetails: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveBatchDetails } = SaveBatchDetailSlice.actions;

export default SaveBatchDetailSlice.reducer;
