import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const updateUserProfilePicApiUrl = '/host-company/admin-setting/profile-picture';
const updateCompanyProfilePicApiUrl = '/host-company/profile/company-logo';

export const UpdateProfilePicApi = createApi({
  reducerPath: 'UpdateProfilePicApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    updateUserProfilePic: builder.mutation({
      query: (body) => {
        const url = updateUserProfilePicApiUrl;
        return { url, method: 'PUT', body };
      },
    }),
    updateCompanyProfilePic: builder.mutation({
      query: (body) => {
        const url = updateCompanyProfilePicApiUrl;
        return { url, method: 'PUT', body };
      },
    }),
    deleteUserProfilePic: builder.mutation({
      query: () => {
        const url = updateUserProfilePicApiUrl;
        return { url, method: 'DELETE' };
      },
    }),
    deleteCompanyProfilePic: builder.mutation({
      query: () => {
        const url = updateCompanyProfilePicApiUrl;
        return { url, method: 'DELETE' };
      },
    }),
  }),
});

export const {
  useUpdateUserProfilePicMutation,
  useUpdateCompanyProfilePicMutation,
  useDeleteUserProfilePicMutation,
  useDeleteCompanyProfilePicMutation,
} = UpdateProfilePicApi;
export default UpdateProfilePicApi.reducer;
