import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetInternListURL = 'intern-services/list';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getInternList = createAsyncThunk(
  'getInternList',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(GetInternListURL, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetInternListSlice = createSlice({
  name: 'GetInternList',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get list of intern state handling */
      .addCase(getInternList.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternList.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getInternList.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternListState = (state) => state.GetInternList;
export default GetInternListSlice.reducer;
