import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetFeedbackQuestionsAndOptionsURL = '/common-services/feedback-reasons/list';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getFeedbackQuestionsAndOptions = createAsyncThunk(
  'getFeedbackQuestionsAndOptions',
  async (request, { rejectWithValue, fulfillWithValue, signal }) => {
    try {
      const result = await axios.get(GetFeedbackQuestionsAndOptionsURL, {
        signal,
      });
      if (result?.data?.status === 200) {
        return fulfillWithValue(result?.data?.data);
      }
      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const GetFeedbackQuestionsAndOptionsSlice = createSlice({
  name: 'getFeedbackQuestionsAndOptions',
  initialState,
  extraReducers: {
    [getFeedbackQuestionsAndOptions.pending]: (state) => ({
      ...state,
      status: 'Pending',
      isLoading: true,
    }),
    [getFeedbackQuestionsAndOptions.fulfilled]: (state, action) => ({
      ...state,
      status: 'Success',
      response: action?.payload?.payload,
      isLoading: false,
    }),
    [getFeedbackQuestionsAndOptions.rejected]: (state, error) => ({
      ...state,
      error,
      status: 'Failed',
      isLoading: false,
    }),
  },
});

export const getFeedbackQuestionsAndOptionsState = (state) => state.GetFeedbackQuestionsAndOptions;
export default GetFeedbackQuestionsAndOptionsSlice.reducer;
