import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';
import { InternDetails } from './Dashboard.d';

const getOnboardingStatusUrl = 'intern-service/get-onboarding-status';
const getInternDetailsUrl = 'intern-service/get-intern-by-uuid/';
const getBatchDetailsUrl = 'intern-service/application/batch-detail';
const getInternBasicDetailInfoUrl = 'intern-service/intern-dashboard/basic-details';
const getLoginInternDetails = 'intern-service/login-intern-details';

export const DashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: customBaseQuery,
  tagTypes: ['internDetails'],
  endpoints: (builder) => ({
    getOnboardingStatus: builder.query({
      query: () => {
        const url = getOnboardingStatusUrl;
        return { url, method: 'GET', body: null };
      },
    }),
    getInternByUuid: builder.query({
      query: ({ uuid = '' }) => {
        const url = getInternDetailsUrl + uuid;
        return { url, method: 'GET', body: null };
      },
    }),
    getInternBatchDetails: builder.query({
      query: () => {
        const url = getBatchDetailsUrl;
        return { url, method: 'GET', body: null };
      },
    }),
    getInternBasicDetailInfo: builder.query({
      query: () => {
        const url = getInternBasicDetailInfoUrl;
        return { url, method: 'GET', body: null };
      },
    }),
    getLoginInternDetails: builder.query<InternDetails, null>({
      query: () => {
        const url = getLoginInternDetails;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['internDetails'],
    }),
    updateInternTimezone: builder.mutation({
      query: (body) => {
        const url = 'intern-service/intern-profile/update-intern-timezone';
        return { url, method: 'POST', body };
      },
      invalidatesTags: ['internDetails'],
    }),
    updateAccountDetails: builder.mutation({
      query: (body) => {
        const url = '/intern-service/update-account-settings-details';
        return { url, method: 'POST', body };
      },
      invalidatesTags: ['internDetails'],
    }),
  }),
});

export const {
  useGetOnboardingStatusQuery,
  useGetInternByUuidQuery,
  useGetInternBatchDetailsQuery,
  useGetInternBasicDetailInfoQuery,
  useGetLoginInternDetailsQuery,
  useUpdateInternTimezoneMutation,
  useUpdateAccountDetailsMutation,
} = DashboardApi;
export default DashboardApi.reducer;
