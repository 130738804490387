import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetAdminDetailsURL = '/host-company/admin-setting/user/profile';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const getAdminDetails = createAsyncThunk(
  'getAdminDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(GetAdminDetailsURL)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetAdminDetailsSlice = createSlice({
  name: 'GetAdminDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get admin details state handling */
      .addCase(getAdminDetails.pending, (state) => ({
        ...state,
        status: 'Pending',
        isLoading: true,
      }))
      .addCase(getAdminDetails.fulfilled, (state, action) => ({
        ...state,
        status: action?.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action?.payload?.data?.payload,
        isLoading: false,
      }))
      .addCase(getAdminDetails.rejected, (state) => ({
        ...state,
        status: 'Failed',
        isLoading: false,
      }));
  },
});

export const getAdminDetailsState = (state) => state.GetAdminDetails;
export default GetAdminDetailsSlice.reducer;
