import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';

const GetProfilePictureURL = 'intern-service/intern-profile/profile-pic/list';
const SaveProfilePictureURL = 'intern-service/intern-profile/profile-pic/edit';

export const GetProfilePictureApi = createApi({
  reducerPath: 'profilePictureApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ProfilePicture'],
  endpoints: (builder) => ({
    getProfilePicture: builder.query({
      query: () => ({ url: GetProfilePictureURL, method: 'GET' }),
      providesTags: ['ProfilePicture'],
    }),
    saveProfilePicture: builder.mutation({
      query: (body) => ({ url: SaveProfilePictureURL, method: 'POST', body }),
      invalidatesTags: ['ProfilePicture'],
    }),
  }),
});

export const {
  useGetProfilePictureQuery,
  useLazyGetProfilePictureQuery,
  useSaveProfilePictureMutation,
} = GetProfilePictureApi;

export default GetProfilePictureApi.reducer;
