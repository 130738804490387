import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetOtherMediaDetailsURL = 'host-company/get-media-details';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};



export const getOtherMediaDetails = createAsyncThunk(
  'getOtherMediaDetails',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    const result = await axios
      .get(GetOtherMediaDetailsURL)
      .then((response) => fulfillWithValue(response.data))
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetOtherMediaDetailsSlice = createSlice({
  name: 'GetOtherMediaDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOtherMediaDetails.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getOtherMediaDetails.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(getOtherMediaDetails.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getOtherMediaDetailsState = (state) => state.GetOtherMediaDetails;
export default GetOtherMediaDetailsSlice.reducer;
