import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const CreateVideoAnswerURL = 'intern-service/profile/video/create';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const createVideoAnswers = createAsyncThunk(
  'createVideoAnswers',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(CreateVideoAnswerURL, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const CreateVideoAnswersSlice = createSlice({
  name: 'CreateVideoAnswers',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      /* generate create intern state handling */
      .addCase(createVideoAnswers.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(createVideoAnswers.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(createVideoAnswers.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getCreateVideoAnswersState = (state) => state.CreateVideoAnswers;
export const { reset } = CreateVideoAnswersSlice.actions;
export default CreateVideoAnswersSlice.reducer;
