import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const SaveCompanyProfileURL = 'host-company/save-company-profile';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveCompanyProfile = createAsyncThunk(
  'saveCompanyProfile',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(SaveCompanyProfileURL, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const SaveCompanyProfileSlice = createSlice({
  name: 'SaveCompanyProfile',
  initialState,
  reducers: {
    setSaveCompanyProfileStatus: (state, action) => {
      state.status = action.payload.status;
    },
  },
  extraReducers(builder) {
    builder
      /* generate save basic info of intern state handling */
      .addCase(saveCompanyProfile.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(saveCompanyProfile.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(saveCompanyProfile.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action.payload.response;
      });
  },
});

export const getSaveCompanyProfileState = (state) => state.SaveCompanyProfile;
export const { setSaveCompanyProfileStatus } = SaveCompanyProfileSlice.actions;
export default SaveCompanyProfileSlice.reducer;
