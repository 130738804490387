import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';

const getCompanyIndustriesUrl = '/common-services/industry';
const getCountriesUrl = '/common-services/location/get-country-list';
const getEmployeesListUrl = '/common-services/number-of-employees/get-list';
const getProjectsListUrl = '/common-services/company-project/list';
const getRemoteWorkToolsUrl = '/common-services/remote-work-tools';
const getHcPocsUrl = '/host-company/pocs';
const getHostCompanyPocDetailsByIdURL = '/host-company/poc/user';
const getTermsAndConditionsURL = '/common-services/terms-conditions/list';

export const CompanyServices = createApi({
  reducerPath: 'CompanyServices',
  baseQuery: customBaseQuery,
  tagTypes: [
    'CompanyIndustries',
    'CountriesList',
    'EmployeesList',
    'ProjectsList',
    'RemoteWorkTools',
    'RacialEthnicGroupList',
    'DisabledList',
    'EducationLevel',
    'SkillList',
    'SkillToImprove',
  ],

  endpoints: (builder) => ({
    getCompanyIndustries: builder.query({
      query: (searchParams) => {
        let url = getCompanyIndustriesUrl;
        if (searchParams) {
          const params = new URLSearchParams(searchParams);
          url = `${getCompanyIndustriesUrl}?${params.toString()}`;
        }
        return { url, method: 'GET', body: null };
      },
      providesTags: ['CompanyIndustries'],
      transformResponse: (response) => {
        return response?.data?.payload?.results.map((item) => ({
          label: item.industry_name,
          value: item.industry_id,
        }));
      },
    }),
    getCountries: builder.query({
      query: () => {
        const url = getCountriesUrl;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['CountriesList'],
      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      },
    }),
    getRacialEthnicGroupList: builder.query({
      query: () => {
        const url = 'common-services/racial-ethnic-group';
        return { url, method: 'GET', body: null };
      },
      providesTags: ['RacialEthnicGroupList'],
      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          label: item?.racial_ethnic_group_name,
          value: item?.racial_ethnic_group_id,
        }));
      },
    }),
    getDisabledList: builder.query({
      query: () => {
        const url = 'common-services/disabled/list';
        return { url, method: 'GET', body: null };
      },
      providesTags: ['DisabledList'],
      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          label: item?.disabled_name,
          value: item?.disabled_id,
        }));
      },
    }),
    getSkillList: builder.query({
      query: () => {
        const url = 'common-services/career-skill/list';
        return { url, method: 'GET', body: null };
      },
      providesTags: ['SkillList'],
      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          label: item?.skill_name,
          value: item?.skill_id,
        }));
      },
    }),
    getSkillToImprove: builder.query({
      query: () => {
        const url = 'intern-service/application/skill-to-improve';
        return { url, method: 'GET', body: null };
      },
      providesTags: ['SkillToImprove'],
    }),
    getProjectsByCareerField: builder.query({
      query: () => ({
        url: 'intern-service/application/career-field/projects',
        method: 'GET',
        body: null,
      }),
      transformResponse: (response) => {
        return response?.data?.payload?.result?.map((item) => ({
          label: item?.project_name,
          value: item?.id,
        }));
      },
    }),
    getEducationLevel: builder.query({
      query: () => {
        const url = 'common-services/education-level/list';
        return { url, method: 'GET', body: null };
      },
      providesTags: ['EducationLevel'],
      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          label: item?.education_level_name,
          value: item?.education_level_id,
          is_certificate_course: item?.is_certificate_course,
        }));
      },
    }),
    getEmployeesList: builder.query({
      query: () => {
        const url = getEmployeesListUrl;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['EmployeesList'],
      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          label: item.number_of_employees,
          value: item.id,
        }));
      },
    }),

    getProjectList: builder.query({
      query: (body) => {
        let url = getProjectsListUrl;
        if (body) {
          const filters = body?.filter;
          url += '?';
          Object.keys(filters).forEach((key, index) => {
            url += index > 0 ? `&${key}=${filters[key]}` : `${key}=${filters[key]}`;
          });
        }
        return { url, method: 'GET', body: null };
      },
      providesTags: ['ProjectsList'],
      transformResponse: (response) => {
        return response?.data?.payload;
      },
    }),
    getRemoteWorkTools: builder.query({
      query: () => {
        const url = getRemoteWorkToolsUrl;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['RemoteWorkTools'],

      transformResponse: (response) => {
        return response?.data?.payload?.map((item) => ({
          label: item.tools_name,
          value: item.remotework_tools_id,
        }));
      },
    }),

    getHcPocs: builder.query({
      query: (request) => {
        const params = new URLSearchParams(request);
        const url = `${getHcPocsUrl}?${params}`;
        return { url, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload;
      },
    }),

    getHostCompanyPocDetailsById: builder.query({
      query: (request) => {
        const url = `${getHostCompanyPocDetailsByIdURL}?user_id=${request?.user_id}`;
        return { url, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload;
      },
    }),

    getTermsAndConditions: builder.query({
      query: () => {
        const url = getTermsAndConditionsURL;
        return { url, method: 'GET', body: null };
      },
      transformResponse: (response) => {
        return response?.data?.payload;
      },
    }),
  }),
});
export const {
  useGetCompanyIndustriesQuery,
  useGetCountriesQuery,
  useGetEmployeesListQuery,
  useGetProjectListQuery,
  useGetRemoteWorkToolsQuery,
  useGetHcPocsQuery,
  useGetHostCompanyPocDetailsByIdQuery,
  useGetTermsAndConditionsQuery,
  useGetRacialEthnicGroupListQuery,
  useGetDisabledListQuery,
  useGetEducationLevelQuery,
  useGetSkillListQuery,
  useGetSkillToImproveQuery,
  useGetProjectsByCareerFieldQuery,
} = CompanyServices;
export default CompanyServices.reducer;
