import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'intern-service/intern-offer/accept-offer-B2B';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const acceptB2B = createAsyncThunk(
  'acceptB2B',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const AcceptB2BSlice = createSlice({
  name: 'AcceptB2B',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get list of intern state handling */
      .addCase(acceptB2B.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(acceptB2B.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(acceptB2B.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const acceptB2BState = (state) => state.AcceptB2B;
export default AcceptB2BSlice.reducer;
