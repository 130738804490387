import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const SaveCommunicationWithSupervisorUrl = 'intern-service/weekly-feedback-loop/question/';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveCommunicationWithSupervisor = createAsyncThunk(
  'saveCommunicationWithSupervisor',
  async (request, { rejectWithValue }) => {
    const weeklyFeedbackId = request.weekly_feedback_id;
    delete request.weekly_feedback_id;
    const result = await axios
      .post(
        `${SaveCommunicationWithSupervisorUrl + weeklyFeedbackId}/communication-with-supervisor`,
        request,
      )
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveCommunicationWithSupervisorSlice = createSlice({
  name: 'SaveCommunicationWithSupervisor',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate save communication-with-supervisor state handling */
      .addCase(saveCommunicationWithSupervisor.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(saveCommunicationWithSupervisor.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(saveCommunicationWithSupervisor.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const saveCommunicationWithSupervisorState = (state) =>
  state.SaveCommunicationWithSupervisor;
export default SaveCommunicationWithSupervisorSlice.reducer;
