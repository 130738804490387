import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = '/host-company/profile/update-about-company';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveAdditionalDetails = createAsyncThunk(
  'saveAdditionalDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveAdditionalDetailsSlice = createSlice({
  name: 'SaveAdditionalDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(saveAdditionalDetails.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(saveAdditionalDetails.fulfilled, (state, action) => {
        state.status = action?.payload?.isSuccess ? 'Success' : 'Failed';
        state.response = action?.payload?.payload;
      })
      .addCase(saveAdditionalDetails.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSaveAdditionalDetailsState = (state) => state.SaveAdditionalDetails;
export default SaveAdditionalDetailsSlice.reducer;
