import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetProfileCompletionURL = 'intern-service/intern-dashboard/profile-completion';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getProfileCompletion = createAsyncThunk(
  'getProfileCompletion',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(GetProfileCompletionURL)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetProfileCompletionSlice = createSlice({
  name: 'GetProfileCompletion',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getProfileCompletion.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getProfileCompletion.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getProfileCompletion.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getProfileCompletionState = (state) => state.GetProfileCompletion;
export default GetProfileCompletionSlice.reducer;
