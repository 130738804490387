import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const getUpcomingInterviewsUrl = '/host-company/dashboard/interviews';

export const DashboardApi = createApi({
  reducerPath: 'DashboardApi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getUpcomingInterviews: builder.query({
      query: () => {
        return { url: getUpcomingInterviewsUrl, method: 'GET', body: null };
      },
    }),
  }),
});

export const { useGetUpcomingInterviewsQuery } = DashboardApi;
export default DashboardApi.reducer;
