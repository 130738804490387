import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';
import { getOffers } from './GetOffers';
import { clearStateValues } from './SaveOfferStep2';
import { resetFirstStepOfferResponse } from './SaveOfferStep1';

const url = '/host-company/offer/send-intern-offer';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const saveOfferStep3 = createAsyncThunk(
  'saveOfferStep3',
  async (request, { rejectWithValue, dispatch, getState }) => {
    const { GetOffers } = getState();
    const result = await axios
      .post(url, request)
      .then((response) => {
        if (response?.data?.status === 200) {
          dispatch(resetFirstStepOfferResponse());
          dispatch(clearStateValues());
          dispatch(getOffers(GetOffers.getOfferApiCallParams));
        }
        return response?.data?.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveOfferStep3Slice = createSlice({
  name: 'SaveOfferStep2',
  initialState,
  reducers: {
    resetSaveOfferStatus: (state) => {
      state.status = 'idle';
      state.response = null;
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(saveOfferStep3.pending, (state) => {
        state.status = 'Pending';
        state.isLoading = true;
      })
      .addCase(saveOfferStep3.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
        state.isLoading = false;
      })
      .addCase(saveOfferStep3.rejected, (state) => {
        state.status = 'Failed';
        state.isLoading = false;
      });
  },
});

export const saveOfferStep3State = (state) => state.SaveOfferStep3;
export const { resetSaveOfferStatus } = SaveOfferStep3Slice.actions;

export default SaveOfferStep3Slice.reducer;
