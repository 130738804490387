import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

import { objectToQueryString } from '../../../../Utils/Common/utils';
const getAccountInterviewsCreditsURL = '/host-company/admin-setting/interview/credits';
const getInterviewCreditsURL = '/host-company/admin-setting/credits';
export const CreditsApi = createApi({
  reducerPath: 'creditsAPi',
  baseQuery: customBaseQuery,
  endpoints: (builder) => ({
    getInterviewCredits: builder.query({
      query: (body) => ({
        url: getAccountInterviewsCreditsURL,
        method: 'GET',
        body,
      }),
      transformResponse: (response) => response?.data?.payload,
    }),
    getCreditsTransactions: builder.query({
      query: (request) => {
        const queryString = request ? objectToQueryString(request) : '';
        const updatedUrl = queryString
          ? `${getInterviewCreditsURL}?${queryString}`
          : getInterviewCreditsURL;
        return {
          url: updatedUrl,
          method: 'GET',
          body: null,
        };
      },
    }),
  }),
});

export const { useGetInterviewCreditsQuery, useGetCreditsTransactionsQuery } = CreditsApi;

export default CreditsApi.reducer;
