import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

export const GetAdditionalQuestionApi = createApi({
  reducerPath: 'GetAdditionalQuestionApi',
  baseQuery: customBaseQuery,
  tagTypes: ['videoList', 'getAdditionalQuestion'],
  endpoints: (builder) => ({
    getAdditionalQuestion: builder.query({
      query: () => ({
        url: 'intern-service/application/additional-question',
        method: 'GET',
        body: null,
      }),
      providesTags: ['getAdditionalQuestion'],
    }),
    saveAdditionalQuestion: builder.mutation({
      query: (body) => {
        return { url: 'intern-service/application/additional-question', method: 'POST', body };
      },
      invalidatesTags: ['getAdditionalQuestion'],
    }),
  }),
});

export const { useGetAdditionalQuestionQuery, useSaveAdditionalQuestionMutation } =
  GetAdditionalQuestionApi;
export default GetAdditionalQuestionApi.reducer;
