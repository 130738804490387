import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';
import { showErrorMessage } from '../Notification';

const InterviewFeedbackIsAttendURL = '/intern-service/interview-feedback/is-attend';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
  isLoading: false,
};

export const interviewFeedbackIsAttend = createAsyncThunk(
  'interviewFeedbackIsAttend',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(InterviewFeedbackIsAttendURL, request);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
      return null;
    } catch (error) {
      if (error?.response?.data?.data?.message) {
        dispatch(
          showErrorMessage({
            title: error?.response?.data?.data?.message,
          })
        );
      }
      return rejectWithValue(error);
    }
  }
);

const InterviewFeedbackIsAttendSlice = createSlice({
  name: 'InterviewFeedbackIsAttend',
  initialState,
  reducers: {
    reducers: {
      interviewFeedbackIsAttendStateReset: (state) => ({
        ...state,
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      }),
    },
  },
  extraReducers: {
    [interviewFeedbackIsAttend.pending]: (state) => ({
      ...state,
      status: 'Pending',
      isLoading: true,
    }),
    [interviewFeedbackIsAttend.fulfilled]: (state, action) => ({
      ...state,
      status: 'Success',
      response: action?.payload?.payload,
      isLoading: false,
    }),
    [interviewFeedbackIsAttend.rejected]: (state, error) => ({
      ...state,
      status: 'Failed',
      error: error?.payload?.response?.data,
      isLoading: false,
    }),
  },
});

export const interviewFeedbackIsAttendState = (state) => state.InterviewFeedbackIsAttend;
export const { interviewFeedbackIsAttendStateReset } = InterviewFeedbackIsAttendSlice.actions;
export default InterviewFeedbackIsAttendSlice.reducer;
