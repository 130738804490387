import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetInternOfferDetailsUrl = 'intern-service/weekly-feedback-loop/offer-detail';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getInternOfferDetails = createAsyncThunk(
  'getInternOfferDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(GetInternOfferDetailsUrl, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetInternOfferDetailsSlice = createSlice({
  name: 'GetInternOfferDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get offer details state handling */
      .addCase(getInternOfferDetails.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getInternOfferDetails.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(getInternOfferDetails.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getInternOfferDetailsState = (state) => state.GetInternOfferDetails;
export default GetInternOfferDetailsSlice.reducer;
