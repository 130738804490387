import React, { lazy } from 'react';

const Dashboard = lazy(() => import('../Intern/Pages/Dashboard/Dashboard'));
const ApplicationFeedbackPages = lazy(() => import('../Intern/Pages/ApplicationBeta/MainPage'));
const ApplicationProfile = lazy(
  () => import('../Intern/Pages/ApplicationBeta/LandingPage/LandingPage'),
);
const ApplicationList = lazy(() => import('../Intern/Pages/ApplicationList/ApplicationList'));
const PlacementTracker = lazy(() => import('../Intern/Pages/MatchTracker/MatchTracker'));
const PlacementTrackerCompany = lazy(
  () => import('../Intern/Pages/MatchTrackerCompany/MatchTrackerCompany'),
);
const PreviewCertificate = lazy(() => import('../Intern/Pages/CertificateHosting/Certificate'));
const InternshipManagement = lazy(
  () => import('../Intern/Pages/InternshipManagement/ManageInternships'),
);
const InterviewTimeSlots = lazy(
  () => import('../Intern/Pages/InterviewTimeSlots/InterviewTimeSlots'),
);
const Onboarding = lazy(() => import('../Intern/Pages/Onboarding/Onboarding'));
const VerifyWhatsapp = lazy(() => import('../Intern/Pages/VerifyWhatsapp'));
const ApplicationLandingPage = lazy(
  () => import('../Intern/Pages/ApplicationBeta/LandingPage/LandingPage'),
);
const VideoAnswer = lazy(() => import('../Intern/Pages/Dashboard/VideoAnswer'));
const VideoGetStarted = lazy(() => import('../Intern/Pages/Dashboard/VideoAnswerGetStarted'));
const VideoWrap = lazy(() => import('../Intern/Pages/Dashboard/VideoAnswerWrap'));
const DeclineOffer = lazy(() => import('../Intern/Components/Dashboard/DeclineOffer'));
const RequestReceived = lazy(() => import('../Intern/Components/Dashboard/RequestReceived'));
const TermsAndConditions = lazy(() => import('../Intern/Components/Dashboard/TermsAndConditions'));
const WeeklyCheckpoint = lazy(
  () => import('../Intern/Components/InternshipManagement/WeeklyFeebackTemplate'),
);
const ReviewOffer = lazy(() => import('../Intern/Components/Dashboard/ReviewOffer'));
const Checkout = lazy(() => import('../Intern/Pages/Application/CheckoutPage'));
const ApplicationSuccess = lazy(() => import('../Intern/Pages/Application/SuccessPage'));
const OfferSuccess = lazy(() => import('../Intern/Pages/Application/SuccessPage'));
const Login = lazy(() => import('../Intern/Pages/SignupSignin/Login'));
const BasicDetails = lazy(() => import('../Intern/Pages/SignupSignin/BasicDetails'));
const BatchSelect = lazy(() => import('../Intern/Pages/SignupSignin/BatchSelect'));
const EnrollmentNotStarted = lazy(
  () => import('../Intern/Pages/SignupSignin/EnrollmentNotStarted'),
);
const CampaignEnded = lazy(() => import('../Intern/Pages/SignupSignin/CampaignEnded'));
const TermsAndCondition = lazy(
  () => import('../Intern/Pages/TermsAndConditions/TermsAndCondition'),
);
const TermsAndConditionB2B = lazy(
  () => import('../Intern/Pages/TermsAndConditions/TermsAndConditionB2B'),
);

// Routes
export const privateInternRoutesWithHeaderAndNavBar = [
  { path: 'dashboard', element: <Dashboard /> },
  { path: 'application-profile', element: <ApplicationProfile /> },
  { path: 'application-list', element: <ApplicationList /> },
  { path: 'placement-tracker', element: <PlacementTracker /> },
  { path: 'placement-tracker/:candidatureId', element: <PlacementTrackerCompany /> },
  { path: 'preview-certificate/:uuid', element: <PreviewCertificate /> },
  { path: 'internship-management', element: <InternshipManagement /> },
  { path: 'interview-time-slots', element: <InterviewTimeSlots /> },
];

export const privateInternRoutesWithHeader = [
  { path: 'onboarding', element: <Onboarding /> },
  {
    path: 'application',
    element: <ApplicationFeedbackPages />,
    children: [
      { path: ':applicationPageUrl', element: <></> },
      { path: ':id', element: <></> },
    ],
  },
  {
    path: 'application-feedback-pages',
    element: <ApplicationFeedbackPages />,
    children: [
      { path: ':applicationPageUrl', element: <></> },
      { path: ':id', element: <></> },
    ],
  },
  { path: 'verify-whatsapp', element: <VerifyWhatsapp /> },
  { path: 'application-landing-page', element: <ApplicationLandingPage /> },
  {
    path: 'video-answer',
    element: <VideoAnswer setPage={undefined} isView={undefined} isFeedback={undefined} />,
  },
  { path: 'video-get-started', element: <VideoGetStarted /> },
  { path: 'video-wrap', element: <VideoWrap /> },
  { path: 'decline-offer/:offer_id', element: <DeclineOffer /> },
  { path: 'request-received/:offer_id', element: <RequestReceived /> },
  { path: 'term-condition/:offer_id', element: <TermsAndConditions /> },
  {
    path: 'internship-management/weekly-checkpoint',
    element: <WeeklyCheckpoint />,
    children: [
      { path: ':weeklyCheckpointUrl', element: <></> },
      { path: ':id', element: <></> },
    ],
  },
  { path: 'review-offer/:offer_id', element: <ReviewOffer /> },
];

export const privateInternRoutes = [
  { path: 'checkout', element: <Checkout /> },
  { path: 'application-success', element: <ApplicationSuccess /> },
  { path: 'offer-success', element: <OfferSuccess /> },
];

export const internPublicRoutes = [
  { path: 'terms-and-conditions', element: <TermsAndCondition /> },
  { path: 'terms-and-conditions-partner', element: <TermsAndConditionB2B /> },
  { path: 'sign-up/enrolment-not-started', element: <EnrollmentNotStarted /> },
  { path: 'sign-up/campaign-expired', element: <CampaignEnded /> },
];

export const internSignUpRoutes = [
  { path: 'sign-up/basic-details', element: <BasicDetails /> },
  { path: 'sign-up/batch-select', element: <BatchSelect /> },
];

export const internBaseRoutes = [
  {
    index: true,
    element: <Login />,
  },
  { path: 'get-started', element: <Login /> },
];
