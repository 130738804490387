import { createSlice } from '@reduxjs/toolkit';

const shortlistActionSlice = createSlice({
  name: 'shortlistAction',
  initialState: {
    refetch: null,
    pageNumberForShortListBrowse: null,
  },
  reducers: {
    isCandidateShortlisted: (state, action) => {
      return action.payload;
    },
  },
});

export const { isCandidateShortlisted } = shortlistActionSlice.actions;

export default shortlistActionSlice.reducer;
