import {  createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: false,
    error: null,
    response: null,
  };

  const LoadLocalesSlice = createSlice({
    name: 'LoadLocales',
    initialState,
    reducers: {
      setLocalesFetchedStatus: (state) => {
        state.status = true
        }},
  });

  export const getLoadLocalesState = (state) => state.LoadLocales;
  export const { setLocalesFetchedStatus } = LoadLocalesSlice.actions;

  export default LoadLocalesSlice.reducer;