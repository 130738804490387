import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetInternDetailsForRegURL = 'intern-service/get-intern-by-uuid-reg/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getInternByUuidForReg = createAsyncThunk(
  'getInternDetailsForReg',
  async (request, { rejectWithValue }) => {
    const { uuid } = request;
    const result = await axios
      .get(GetInternDetailsForRegURL + uuid)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetInternDetailsForRegSlice = createSlice({
  name: 'GetInternDetailsForReg',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get details of intern state handling */
      .addCase(getInternByUuidForReg.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getInternByUuidForReg.fulfilled, (state, action) => ({
        ...state,
        status: 'Success',
        response: action.payload,
      }))
      .addCase(getInternByUuidForReg.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getInternByUuidForRegState = (state) => {
  return state.GetInternDetailsForReg;
};
export default GetInternDetailsForRegSlice.reducer;
