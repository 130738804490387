import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const inviteNewColleaguesURL = '/host-company/admin-setting/invite';
const getAccountMembersURL = '/host-company/admin-setting/users';
const getAccountInterviewsURL = '/host-company/admin-setting/interviews';
const getAccountInterviewsCreditsURL = '/host-company/admin-setting/interview/credits';
const changeUserRoleURL = '/host-company/admin-setting/user/role';
const activateOrDeactivateUserURL = '/host-company/admin-setting/user/status';
const remindHostCompanyUserURL = '/host-company/admin-setting/remind/user';

const initialState = {
  inviteNewColleague: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  remindHostCompanyUser: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  getAccountMembers: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
    count: 0,
  },
  getAccountInterviews: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
    count: 0,
  },
  getAccountInterviewsCredits: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  changeUserRoleDetails: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  activateOrDeactivateUserDetails: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  getAdminUsersRequestParameters: {},
};

export const inviteNewColleagues = createAsyncThunk(
  'inviteNewColleagues',
  async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
      const {
        AdminPageDetails: { getAdminUsersRequestParameters },
      } = getState();
      const result = await axios.post(inviteNewColleaguesURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(getAccountMembersDetails(getAdminUsersRequestParameters));
        return fulfillWithValue(result?.data?.data);
      }
      if (!result?.data?.data?.isSuccess) {
        return rejectWithValue(result?.data?.data?.message);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getAccountMembersDetails = createAsyncThunk(
  'getAccountMembersDetails',
  async (request, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const result = await axios.post(getAccountMembersURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(saveAdminUserRequest(request));
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const changeUserRole = createAsyncThunk(
  'changeUserRole',
  async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
      const {
        AdminPageDetails: { getAdminUsersRequestParameters },
      } = getState();
      const result = await axios.put(changeUserRoleURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(getAccountMembersDetails(getAdminUsersRequestParameters));
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const activateOrDeactivateUser = createAsyncThunk(
  'activateOrDeactivateUser',
  async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
      const {
        AdminPageDetails: { getAdminUsersRequestParameters },
      } = getState();
      const result = await axios.put(activateOrDeactivateUserURL, request);
      if (result?.data?.data?.isSuccess) {
        dispatch(getAccountMembersDetails(getAdminUsersRequestParameters));
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getAccountInterviewsDetails = createAsyncThunk(
  'getAccountInterviewsDetails',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.post(getAccountInterviewsURL, request);
      if (result?.data?.data?.isSuccess) {
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const getAccountInterviewsCreditsDetails = createAsyncThunk(
  'getAccountInterviewsCreditsDetails',
  async (request, { rejectWithValue, fulfillWithValue }) => {
    try {
      const result = await axios.get(getAccountInterviewsCreditsURL);
      if (result?.data?.status === 200) {
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const remindHostCompanyUserDetails = createAsyncThunk(
  'remindHostCompanyUserDetails',
  async (request, { rejectWithValue, fulfillWithValue, dispatch, getState }) => {
    try {
      const {
        AdminPageDetails: { getAdminUsersRequestParameters },
      } = getState();
      const result = await axios.post(remindHostCompanyUserURL, request);
      if (result?.data?.status === 200) {
        dispatch(getAccountMembersDetails(getAdminUsersRequestParameters));
        return fulfillWithValue(result?.data?.data);
      }
      return rejectWithValue('Request failed with an unsuccessful response.');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const adminPageDetailsSlice = createSlice({
  name: 'adminPageDetails',
  initialState,
  reducers: {
    saveAdminUserRequest: (state, action) => ({
      ...state,
      getAdminUsersRequestParameters: {
        ...action.payload,
      },
    }),
    inviteNewColleagueStateReset: (state) => ({
      ...state,
      inviteNewColleague: {
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      },
    }),
    changeUserRoleDetailStateReset: (state) => ({
      ...state,
      changeUserRoleDetails: {
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      },
    }),
    remindHostCompanyUserStateReset: (state) => ({
      ...state,
      remindHostCompanyUser: {
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      },
    }),
    activateOrDeactivateUserStateReset: (state) => ({
      ...state,
      activateOrDeactivateUserDetails: {
        status: 'idle',
        error: null,
        response: null,
        isLoading: false,
      },
    }),
  },

  extraReducers: {
    [inviteNewColleagues.pending]: (state) => ({
      ...state,
      inviteNewColleague: {
        ...state.inviteNewColleague,
        status: 'Pending',
        isLoading: true,
      },
    }),
    [inviteNewColleagues.fulfilled]: (state, action) => ({
      ...state,
      inviteNewColleague: {
        ...state.inviteNewColleague,
        status: 'Success',
        response: action?.payload?.payload,
        isLoading: false,
      },
    }),
    [inviteNewColleagues.rejected]: (state, error) => ({
      ...state,
      inviteNewColleague: {
        ...state.inviteNewColleague,
        isLoading: false,
        status: 'Failed',
        error: error,
      },
    }),
    [getAccountMembersDetails.pending]: (state) => ({
      ...state,
      getAccountMembers: {
        ...state.getAccountMembers,
        status: 'Pending',
        isLoading: true,
      },
    }),
    [getAccountMembersDetails.fulfilled]: (state, action) => ({
      ...state,
      getAccountMembers: {
        ...state.getAccountMembers,
        status: 'Success',
        response: action?.payload?.payload?.map((x) => {
          return {
            info: x,
          };
        }),
        count: action?.payload?.count,
        isLoading: false,
      },
    }),
    [getAccountMembersDetails.rejected]: (state, error) => ({
      ...state,
      getAccountMembers: {
        ...state.getAccountMembers,
        isLoading: false,
        status: 'Failed',
        error: error.message,
      },
    }),
    [getAccountInterviewsDetails.pending]: (state) => ({
      ...state,
      getAccountInterviews: {
        ...state.getAccountInterviews,
        status: 'Pending',
        isLoading: true,
      },
    }),
    [getAccountInterviewsDetails.fulfilled]: (state, action) => ({
      ...state,
      getAccountInterviews: {
        ...state.getAccountInterviews,
        status: 'Success',
        response: action?.payload?.payload?.map((x) => {
          return {
            info: x,
          };
        }),
        count: action?.payload?.count,
        isLoading: false,
      },
    }),
    [getAccountInterviewsDetails.rejected]: (state, error) => ({
      ...state,
      getAccountInterviews: {
        ...state.getAccountInterviews,
        isLoading: false,
        status: 'Failed',
        error: error.message,
      },
    }),
    [getAccountInterviewsCreditsDetails.pending]: (state) => ({
      ...state,
      getAccountInterviewsCredits: {
        ...state.getAccountInterviewsCredits,
        status: 'Pending',
        isLoading: true,
      },
    }),
    [getAccountInterviewsCreditsDetails.fulfilled]: (state, action) => ({
      ...state,
      getAccountInterviewsCredits: {
        ...state.getAccountInterviewsCredits,
        status: 'Success',
        response: action?.payload?.payload,
        isLoading: false,
      },
    }),
    [getAccountInterviewsCreditsDetails.rejected]: (state, error) => ({
      ...state,
      getAccountInterviewsCredits: {
        ...state.getAccountInterviewsCredits,
        isLoading: false,
        status: 'Failed',
        error: error.message,
      },
    }),
    [changeUserRole.pending]: (state) => ({
      ...state,
      changeUserRoleDetails: {
        ...state.changeUserRoleDetails,
        status: 'Pending',
        isLoading: true,
      },
    }),
    [changeUserRole.fulfilled]: (state, action) => ({
      ...state,
      changeUserRoleDetails: {
        ...state.changeUserRoleDetails,
        status: 'Success',
        response: action?.payload?.payload,
        isLoading: false,
      },
    }),
    [changeUserRole.rejected]: (state, error) => ({
      ...state,
      changeUserRoleDetails: {
        ...state.changeUserRoleDetails,
        isLoading: false,
        status: 'Failed',
        error: error.message,
      },
    }),
    [activateOrDeactivateUser.pending]: (state) => ({
      ...state,
      activateOrDeactivateUserDetails: {
        ...state.activateOrDeactivateUserDetails,
        status: 'Pending',
        isLoading: true,
      },
    }),
    [activateOrDeactivateUser.fulfilled]: (state, action) => ({
      ...state,
      activateOrDeactivateUserDetails: {
        ...state.activateOrDeactivateUserDetails,
        status: 'Success',
        response: action?.payload?.payload,
        isLoading: false,
      },
    }),
    [activateOrDeactivateUser.rejected]: (state, error) => ({
      ...state,
      activateOrDeactivateUserDetails: {
        ...state.activateOrDeactivateUserDetails,
        isLoading: false,
        status: 'Failed',
        error: error.message,
      },
    }),
    [remindHostCompanyUserDetails.pending]: (state) => ({
      ...state,
      remindHostCompanyUser: {
        ...state.remindHostCompanyUser,
        status: 'Pending',
        isLoading: true,
      },
    }),
    [remindHostCompanyUserDetails.fulfilled]: (state, action) => ({
      ...state,
      remindHostCompanyUser: {
        ...state.remindHostCompanyUser,
        status: 'Success',
        response: action?.payload?.payload,
        isLoading: false,
      },
    }),
    [remindHostCompanyUserDetails.rejected]: (state, error) => ({
      ...state,
      remindHostCompanyUser: {
        ...state.remindHostCompanyUser,
        isLoading: false,
        status: 'Failed',
        error: error.message,
      },
    }),
  },
});

export const adminPageDetailsState = (state) => state.AdminPageDetails;
export default adminPageDetailsSlice.reducer;
export const {
  inviteNewColleagueStateReset,
  changeUserRoleDetailStateReset,
  activateOrDeactivateUserStateReset,
  remindHostCompanyUserStateReset,
  saveAdminUserRequest,
} = adminPageDetailsSlice.actions;
