import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';

const expressInterestApi = 'intern-service/intern-dashboard/express-interest';
const getInterestedInternsApi = '/host-company/internship-plan/interested-interns';
const removeInterestUrl = '/host-company/browse/not-interested';

export const ExpressInterestApi = createApi({
  reducerPath: 'ExpressInterestApi',
  baseQuery: customBaseQuery,
  tagTypes: ['interest', 'relevantIrs'],
  endpoints: (builder) => ({
    getRelevantIrs: builder.query({
      query: (applicationId) => {
        const url = `intern-service/intern-dashboard/${applicationId}/relevant-irs`;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['relevantIrs'],
    }),
    expressInterest: builder.mutation({
      query: (body) => {
        const url = expressInterestApi;
        return { url, method: 'POST', body };
      },
    }),
    getInterestedInterns: builder.query({
      query: (IoIDs) => {
        const url = IoIDs
          ? `${getInterestedInternsApi}?io_ids[]=${IoIDs}`
          : getInterestedInternsApi;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['interest'],
    }),
    removeInternInterest: builder.mutation({
      query: ({ internId }) => ({
        url: `${removeInterestUrl}`,
        method: 'POST',
        body: {
          intern_id: internId,
        },
      }),
      invalidatesTags: ['interest'],
    }),
  }),
});

export const {
  useGetRelevantIrsQuery,
  useExpressInterestMutation,
  useGetInterestedInternsQuery,
  useRemoveInternInterestMutation,
} = ExpressInterestApi;
export default ExpressInterestApi.reducer;
