/* eslint-disable no-unused-vars */

import { createApi } from '@reduxjs/toolkit/query/react';
import axios from '../../../../Axios/Axios';
import { objectToQueryString } from '../../../../Utils/Common/utils';

const UniversityListURL = 'common-services/university';

const axiosBaseQuery =
  () =>
  async ({ url, a, method }) => {
    let result = {};
    try {
      if (method === 'POST') {
        result = await axios.post(url);
      } else {
        result = await axios.get(url);
      }
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const GetUniversityApi = createApi({
  reducerPath: 'GetUniversityApi',
  baseQuery: axiosBaseQuery(),
  staleTime: 1,
  endpoints: (builder) => ({
    getUniversityList: builder.query({
      query: (request) => {
        const queryString = objectToQueryString(request);
        let newUrl = queryString ? `${UniversityListURL}?${queryString}` : UniversityListURL;
        return { url: newUrl, method: 'GET' };
      },
    }),
    getMajorCategoryList: builder.query({
      query: () => ({ url: 'common-services/major/category', method: 'GET' }),
    }),
  }),
});

export const {
  useGetUniversityListQuery,
  useLazyGetUniversityListQuery,
  useGetMajorCategoryListQuery,
} = GetUniversityApi;
export default GetUniversityApi.reducer;
