/* eslint-disable quotes */
import './wdyr';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { datadogLogs } from '@datadog/browser-logs';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';
import { reduxStore } from './Redux/Store';
import { AppContextProvider } from './Utils/context';
const container = document.getElementById('root');
const root = createRoot(container);

let persistor = persistStore(reduxStore);

const ignoredSources = ['mixpanel', 'google analytics', 'clarity', 'flush'];

if (process.env.REACT_APP_CLUSTER === 'prod') {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    forwardReports: 'all',
    sessionSampleRate: 100,
    env: `${process.env.REACT_APP_CLUSTER}:main-app`,
    service: 'fe-main-app',
    version: process.env.REACT_APP_VERSION,
    beforeSend: (log) => {
      const logMessage = log?.message?.toLowerCase();
      const logStack = log?.error?.stack?.toLowerCase() || '';

      const shouldIgnore = ignoredSources.some(
        (source) => logMessage.includes(source) || logStack.includes(source),
      );

      return !shouldIgnore;
    },
  });
}

if (process.env.REACT_APP_CLUSTER !== 'local' && process.env.REACT_APP_CLUSTER !== 'development') {
  Sentry.init({
    dsn: 'https://75bc4c6ce80d4c2e8fe3ab21b91a24b2@o4504966076104704.ingest.sentry.io/4505005520257024',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        autoInject: false,
      }),
    ],
    environment: process.env.REACT_APP_CLUSTER,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Object captured as exception',
      "TypeError: can't access dead object",
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      'NotFoundError: The object can not be found here.',
      "ReferenceError: Can't find variable",
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // Remove below four errors once fixed by Clarity (https://github.com/microsoft/clarity/issues/656)
      "Cannot read properties of null (reading 'sessionId')",
      "null is not an object (evaluating 'Qr.sessionId')",
      "null is not an object (evaluating 'yi.version')",
      "Cannot read properties of null (reading 'version')",
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    beforeSend: (event, hint) => {
      const error = hint?.originalException;
      if (
        error &&
        typeof error !== 'string' &&
        error?.message &&
        (error?.message?.includes('chunk') ||
          error?.message?.includes('Chunk') ||
          error?.message?.includes('Unexpected token'))
      )
        return null;

      return event;
    },
  });
}

root.render(
  <Provider store={reduxStore}>
    <PersistGate persistor={persistor}>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </PersistGate>
  </Provider>,
);
