import React, { lazy } from 'react';

// Lazy imports
const ProfilePage = lazy(() => import('Company/Pages/Profile/PageLayout'));
const InternshipOpportunityPage = lazy(
  () => import('Company/Pages/InternshipOpportunity/InternshipOpportunityPage'),
);
const InternshipsOpportunityDetailsPage = lazy(
  () => import('Company/Pages/InternshipOpportunityDetails/InternshipsOpportunityDetailsPage'),
);
const InternshipOpportunityCandidatePage = lazy(
  () => import('Company/Pages/InternshipOpportunityCandidate/InternshipOpportunityCandidatePage'),
);
const AdminSettingsPage = lazy(() => import('Company/Pages/Admin/Settings'));
const VITalentPoolPage = lazy(() => import('Company/Pages/VITalentPool/VITalentPool'));
const ShortlistCandidatesPage = lazy(
  () => import('Company/Pages/ShortlistCandidates/ShortlistCandidates'),
);
const OverviewPage = lazy(() => import('Company/Pages/Overview/Overview'));
const HiredInternPage = lazy(() => import('Company/Pages/HiredInterns/HiredIntern'));
const OnboardingPage = lazy(() => import('Company/Pages/SignupSignin/Onboarding/index'));
const AlternateUserProfilePage = lazy(
  () => import('Company/Pages/SignupSignin/AlternateUserProfile'),
);
const LoginPage = lazy(() => import('Company/Pages/SignupSignInNew/Login'));
const ProfileDetailsPage = lazy(() => import('Company/Pages/SignupSignin/ProfileDetails'));
const AdditionalInfoPage = lazy(() => import('Company/Pages/SignupSignin/AdditionalInfo'));
const WaitForInvitation = lazy(() => import('Company/Pages/WaitForInvitation'));
const AccountDeactivated = lazy(() => import('Company/Pages/AccountDeactivated'));
const RegisteredAsIntern = lazy(() => import('Company/Pages/RegisteredAsIntern'));
const UnderReview = lazy(() => import('Company/Pages/UnderReview'));

// commented this code because we will release this feature in next release
// const SignUp = lazy(() => import('Company/Pages/SignupSignInNew/SignUp/SignUp'));
// const InvitedUser = lazy(() => import('Company/Pages/SignupSignInNew/InvitedUser/InvitedUser'));

export const privateCompanyRoutesWithHeaderAndNavBar = [
  {
    path: 'profile',
    element: <ProfilePage />,
  },
  {
    path: 'internship-requirement',
    element: <InternshipOpportunityPage />,
  },
  {
    path: 'hiring-management',
    element: <InternshipsOpportunityDetailsPage />,
  },
  {
    path: 'hiring-management/:candidate',
    element: <InternshipOpportunityCandidatePage />,
  },
  {
    oath: 'internship-requirement/:id/:candidate',
    element: <InternshipOpportunityCandidatePage />,
  },
  {
    path: 'settings',
    element: <AdminSettingsPage />,
  },
  {
    path: 'browse-candidates',
    element: <VITalentPoolPage />,
  },
  {
    path: 'shortlisted-candidates',
    element: <ShortlistCandidatesPage />,
  },
  {
    path: 'dashboard',
    element: <OverviewPage />,
  },
  {
    path: 'hired-interns',
    element: <HiredInternPage />,
  },
  {
    path: 'onboarding',
    element: <OnboardingPage />,
  },
];

export const companySignUpRoutes = [
  {
    path: 'invited-user',
    element: <AlternateUserProfilePage />,
  },
  {
    path: 'sign-up/profile-details',
    element: <ProfileDetailsPage />,
  },
  {
    path: 'sign-up/additional-info',
    element: <AdditionalInfoPage />,
  },
  {
    path: 'sign-up/profile-details-poc',
    element: <AlternateUserProfilePage />,
  },
  // commented this code because we will release this feature in next release
  // {
  //   path: 'sign-up',
  //   element: <SignUp />,
  // },
  // {
  //   path: 'invited-user',
  //   element: <InvitedUser />,
  // },
];

export const companyBaseRoutes = [
  {
    index: true,
    element: <LoginPage />,
  },
  {
    path: 'get-started',
    element: <LoginPage />,
  },
  {
    path: 'wait-for-invitation',
    element: <WaitForInvitation />,
  },
  {
    path: 'account-deactivated',
    element: <AccountDeactivated />,
  },
  {
    path: 'registered-as-intern',
    element: <RegisteredAsIntern />,
  },
  {
    path: 'under-review',
    element: <UnderReview />,
  },
];
