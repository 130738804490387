import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const CreateCustomProjectUrl = 'host-company/hired-interns/create-custom-project';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const createCustomProject = createAsyncThunk(
  'createCustomProject',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(CreateCustomProjectUrl, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const CreateCustomProjectSlice = createSlice({
  name: 'CreateCustomProject',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate create custom project state handling */
      .addCase(createCustomProject.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(createCustomProject.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(createCustomProject.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const createCustomProjectState = (state) => state.CreateCustomProject;
export default CreateCustomProjectSlice.reducer;
