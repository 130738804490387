import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';
import { getUtmParams } from '../../../Utils/Common/utils';

const OTPAndHashVerifyURL = '/common-services/otp/v2/verify';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/* structure of request to be
@request = 
{
    "email": "test2@yahoo.com",
    "otp_hash": "64d4d592bc99f84d93f5e3df4",
    "otp": 475437
} 
 */

export const verifyOTPWithHash = createAsyncThunk(
  'verifyOTPWithHash',
  async (request, { rejectWithValue }) => {
    request.otp_hash = request.otp_hash || localStorage.getItem('OTP_HASH');
    const utmParams = getUtmParams(window.location.href);
    const queryParams = new URLSearchParams(utmParams).toString();
    const apiUrl = `${OTPAndHashVerifyURL}?${queryParams}`;
    const result = await axios
      .post(apiUrl, request)
      .then((response) => {
        if (response.data?.data?.isSuccess) {
          return response.data;
        }
        throw rejectWithValue(response?.data);
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const VerifyOTPWithHashSlice = createSlice({
  name: 'VerifyOTPWithHash',
  initialState,
  reducers: {
    setVerifyStatus: (state) => {
      state.status = 'idle';
      state.response = null;
    },
  },
  extraReducers(builder) {
    builder
      /* generate verifyOTPWithHash state handling */
      .addCase(verifyOTPWithHash.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(verifyOTPWithHash.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
        localStorage.removeItem('OTP_HASH');
      })
      .addCase(verifyOTPWithHash.rejected, (state, action) => {
        // Quick fix for error handling
        state.status = 'Failed';
        state.response = action.payload?.response
          ? action.payload?.response
          : { data: action.payload?.payload };
      });
  },
});

export const getVerifyOTPWithHashState = (state) => state.VerifyOTPWithHash;
export const { setVerifyStatus } = VerifyOTPWithHashSlice.actions;

export default VerifyOTPWithHashSlice.reducer;
