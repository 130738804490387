import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const UserOTPURL = 'api/login';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/* structure of request to be
@request = 
{
    "email":"testuser1@gmail.com",
    "latitude":"123456789",
    "longitude":"987654321",
    "source":"OTP",
    "otp": "902872"
 } 
*/

export const userAuthenticate = createAsyncThunk(
  'userAuthenticate',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(UserOTPURL, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const UserAuthenticateSlice = createSlice({
  name: 'UserAuthenticate',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate user authenticate state handling */
      .addCase(userAuthenticate.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(userAuthenticate.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(userAuthenticate.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getUserAuthenticateState = (state) => state.UserAuthenticate;
export default UserAuthenticateSlice.reducer;
