import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

const getInterviewTimeSlotsUrl = 'intern-service/availability';

export const InterviewTimeSlotsApi = createApi({
  reducerPath: 'InterviewTimeSlotsApi',
  baseQuery: customBaseQuery,
  tagTypes: ['interviewTimeSlots'],
  endpoints: (builder) => ({
    getInterviewTimeSlots: builder.query({
      query: () => {
        const url = getInterviewTimeSlotsUrl;
        return { url, method: 'GET', body: null };
      },
      providesTags: ['interviewTimeSlots'],
    }),
    updateInterviewTimeSlots: builder.mutation({
      query: (body) => ({ url: getInterviewTimeSlotsUrl, method: 'POST', body }),
      invalidatesTags: ['interviewTimeSlots'],
    }),
  }),
});

export const { useGetInterviewTimeSlotsQuery, useUpdateInterviewTimeSlotsMutation } =
  InterviewTimeSlotsApi;
export default InterviewTimeSlotsApi.reducer;
