import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetLoginInternDetailsURL = 'intern-service/login-intern-details';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getLoginInternDetails = createAsyncThunk(
  'getLoginInternDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(GetLoginInternDetailsURL)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetLoginInternDetailsSlice = createSlice({
  name: 'GetLoginInternDetails',
  initialState,
  reducers: {
    resetLoginInternDetails: (state, action) => {
      (state.status = action.payload.status || 'idle'),
        (state.response = action.payload.response || null);
    },
  },
  extraReducers(builder) {
    builder
      /* generate get details of intern state handling*/
      .addCase(getLoginInternDetails.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getLoginInternDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getLoginInternDetails.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getLoginInternDetailsState = (state) => state.GetLoginInternDetails;
export const { resetLoginInternDetails } = GetLoginInternDetailsSlice.actions;

export default GetLoginInternDetailsSlice.reducer;
