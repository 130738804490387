import axios from '../Axios/Axios';

export const axiosBaseQuery =
  () =>
  async ({ url, method, body }) => {
    let result = {};
    try {
      if (method === 'POST') {
        result = await axios.post(url, body);
      } else if (method === 'GET') {
        result = await axios.get(url);
      } else if (method === 'PUT') {
        result = await axios.put(url, body);
      } else if (method === 'PATCH') {
        result = await axios.patch(url, body);
      } else if (method === 'DELETE') {
        result = await axios.delete(url);
      }
      if (result?.status === 200) return { data: result.data };
      else {
        return {
          error: {
            status: result?.data?.status || 404,
            data: result?.data?.data || 'Error',
          },
        };
      }
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
