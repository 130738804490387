import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const CheckTokenURL = '/api/check-token';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const checkToken = createAsyncThunk('checkToken', async (request, { rejectWithValue }) => {
  const result = await axios
    .get(CheckTokenURL, request)
    .then((response) => response?.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const CheckTokenSlice = createSlice({
  name: 'CheckToken',
  initialState,
  reducers: {
    resetTokenStatus: (state) => ({ ...state, status: 'idle' }),
  },
  extraReducers(builder) {
    builder
      /* generate check token state handling */
      .addCase(checkToken.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(checkToken.fulfilled, (state, action) => {
        state.status = action.payload?.isSuccess ? 'Success' : 'Failed';
        state.response = action.payload;
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action.payload.response;
      });
  },
});

export const getCheckTokenState = (state) => state.CheckToken;
export const { resetTokenStatus } = CheckTokenSlice.actions;

export default CheckTokenSlice.reducer;
