import {  createSlice } from '@reduxjs/toolkit';

const initialState = {
  feedback :{},
  tempFlow:{}
};

const SetApplicationFeedbackSlice = createSlice({
  name: 'SetApplicationFeedback',
  initialState,
  reducers: {
    setApplicationFeedback: (state, action) => {
        state.feedback = action?.payload?.feedback;  
    },
    setTempFeedbackFlow:(state, action) =>{
      state.tempFlow = action?.payload?.tempFlow
    }
  
  },
});

export const getApplicationFeedback = (state) => state.SetApplicationFeedback;
export const { setApplicationFeedback, setTempFeedbackFlow } = SetApplicationFeedbackSlice.actions;

export default SetApplicationFeedbackSlice.reducer;
