import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/hired-interns/weekly-feedback-loop/question/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

export const getProjectAnswer = createAsyncThunk(
  'getProjectAnswer',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(
        `${url + request.intern_id}/${request.weekly_feedback_id}/project-intern-focus-next-week`,
      )
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GetProjectAnswerSlice = createSlice({
  name: 'GetProjectAnswer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate project asnwer  state handling */
      .addCase(getProjectAnswer.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getProjectAnswer.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(getProjectAnswer.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const getProjectAnswerState = (state) => state.GetProjectAnswer;
export default GetProjectAnswerSlice.reducer;
