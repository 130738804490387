import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/get-general-details';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};



export const getGeneralDetails = createAsyncThunk(
  'getGeneralDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetGeneralDetailsSlice = createSlice({
  name: 'GetGeneralcDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getGeneralDetails.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getGeneralDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getGeneralDetails.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getGeneralDetailsState = (state) => state.GetGeneralDetails;
export default GetGeneralDetailsSlice.reducer;
