import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';

const GetShortListInternURL = 'host-company/browse-intern/get-shortlist-interns';

export const GetShortListInternListApi = createApi({
  reducerPath: 'shortListInternListApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['shortListInternListApi'],
  endpoints: (builder) => ({
    getShortListInternList: builder.query({
      query: (body) => ({ url: GetShortListInternURL, method: 'POST', body }),
    }),
  }),
});

export const { useGetShortListInternListQuery } = GetShortListInternListApi;

export default GetShortListInternListApi.reducer;
