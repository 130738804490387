import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = '/host-company/profile/update-company-profile';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveGeneralDetails = createAsyncThunk(
  'saveGeneralDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(url, request)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);



const SaveGeneralDetailsSlice = createSlice({
  name: 'SaveGeneralDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(saveGeneralDetails.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(saveGeneralDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(saveGeneralDetails.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSaveGeneralDetailsState = (state) => state.SaveGeneralDetails;
export default SaveGeneralDetailsSlice.reducer;
