import { createSlice } from '@reduxjs/toolkit';

const SavePartnerDetailSlice = createSlice({
  name: 'partnerDetail',
  initialState: {},
  reducers: {
    savePartnerDetails: (state, action) => {
      return action.payload;
    },
  },
});

export const { savePartnerDetails } = SavePartnerDetailSlice.actions;

export default SavePartnerDetailSlice.reducer;
