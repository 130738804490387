import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const GetRatingUrl = 'intern-service/weekly-feedback-loop/question/';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const getRating = createAsyncThunk('getRating', async (request, { rejectWithValue }) => {
  const weeklyFeedbackId = request.weekly_feedback_id;
  const result = await axios
    .get(`${GetRatingUrl + weeklyFeedbackId}/rate-your-internship-experience`)
    .then((response) => response.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});

const GetRatingSlice = createSlice({
  name: 'GetRating',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get rating state handling */
      .addCase(getRating.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(getRating.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(getRating.rejected, (state, action) => ({
        ...state,
        status: 'Failed',
        response: { data: action.payload?.response?.data?.data },
      }));
  },
});

export const getRatingState = (state) => state.GetRating;
export default GetRatingSlice.reducer;
