import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../../../Axios/AxiosBaseQuery';

const ShortListInternSaveURL = 'host-company/browse-intern/save-shortlist-intern';

export const ShortListInternSaveApi = createApi({
  reducerPath: 'shortListInternSaveApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['InternShortListSave'],
  endpoints: (builder) => ({
    saveShortListIntern: builder.mutation({
      query: (body) => ({ url: ShortListInternSaveURL, method: 'POST', body }),
    }),
  }),
});

export const { useSaveShortListInternMutation } = ShortListInternSaveApi;

export default ShortListInternSaveApi.reducer;
