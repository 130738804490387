import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/get-basic-details';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};



export const getBasicDetails = createAsyncThunk(
  'getBasicDetails',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .get(url)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetBasicDetailsSlice = createSlice({
  name: 'GetBasicDetails',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get batch details state handling */
      .addCase(getBasicDetails.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getBasicDetails.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getBasicDetails.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getBasicDetailsState = (state) => state.GetBasicDetails;
export default GetBasicDetailsSlice.reducer;
