import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const OTPAndHashGenerateURL = '/common-services/otp/v2/create';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/* structure of request to be
@request = 
{
    'email':'testuser6@gmail.com'
 } 
 */

export const generateOTPWithHash = createAsyncThunk(
  'generateOTPWithHash',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(OTPAndHashGenerateURL, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const GenerateOTPWithHashSlice = createSlice({
  name: 'GenerateOTPWithHash',
  initialState,
  reducers: {
    resetStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      /* generate generateOTPWithHash state handling */
      .addCase(generateOTPWithHash.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(generateOTPWithHash.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
        if (action?.payload?.data?.payload?.otp_hash)
          localStorage?.setItem('OTP_HASH', action?.payload?.data?.payload?.otp_hash);
      })
      .addCase(generateOTPWithHash.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getGenerateOTPWithHashState = (state) => state.GenerateOTPWithHash;
export const { resetStatus } = GenerateOTPWithHashSlice.actions;

export default GenerateOTPWithHashSlice.reducer;
