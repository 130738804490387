import React, { lazy, useEffect, useState, Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import intl from 'react-intl-universal';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeClarity } from './Utils/Common/Clarity';
// Do not remove below line otherwise it will give webpack error
import { getContextUser } from 'Redux/Services/common'; // eslint-disable-line
const InternalServerError = lazy(() => import('./Common/Pages/InternalServerError'));
import useCaptureLastSeen from './Common/Hooks/useCaptureLastSeen';
import { checkToken, getCheckTokenState } from './Redux/Slices/Auth/CheckToken';
import { showErrorMessage } from './Redux/Slices/Common/Notification';
import { useLoadLocalsQuery } from './Redux/Slices/Common/LoadLocalesApi';
import { saveToken } from './Redux/Slices/Auth/SaveToken';
import { setTokenDetails } from './Redux/Slices/Auth/tokenSlice';
import { router } from './Routes/routes';
import { handleUpdateLastActiveTime } from './Utils/Common/helpers/app.helpers';
import { contentDefaultLoader } from './Utils/Common/utils';

function App() {
  const [, setInitIntl] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { data: localesData } = useLoadLocalsQuery();
  const dispatch = useDispatch();
  const getCheckTokenStatus = useSelector(getCheckTokenState);
  useCaptureLastSeen({
    shouldCapture:
      getCheckTokenStatus?.status === 'Success' &&
      !getCheckTokenStatus?.response?.payload?.is_reg_token,
    onUpdateLastActiveTime: handleUpdateLastActiveTime,
  });

  useEffect(() => {
    if (localesData) {
      setInitIntl(localesData);
    }
  }, [localesData]);

  useEffect(() => {
    const search = window.location?.search;

    if (search?.includes('?token')) {
      // if user is coming from magic link then the link will have token as the
      // query param, we need to store the token for sending as the query param in our api
      const urlParams = new URLSearchParams(search);
      const tokenValue = urlParams.get('token');
      dispatch(saveToken({ token: tokenValue }));
    }

    dispatch(checkToken());
  }, []);

  useEffect(() => {
    if (getCheckTokenStatus?.status === 'Success') {
      const tokenDetails = getCheckTokenStatus?.response?.payload;
      dispatch(setTokenDetails(tokenDetails));
      setIsLoading(false);
      if (process.env.REACT_APP_CLUSTER === 'prod') {
        initializeClarity();
      }
    }
    if (getCheckTokenStatus?.status === 'Failed') {
      if (
        !(
          getCheckTokenStatus?.response?.data?.message?.includes('Unauthorized') ||
          getCheckTokenStatus?.response?.data?.data?.message?.includes('Unauthorized')
        )
      ) {
        dispatch(showErrorMessage({ title: intl.get('common.error') }));
      }
      dispatch(setTokenDetails(null));
      setIsLoading(false);
    }
  }, [getCheckTokenStatus]);

  const InternalServerErrorWithSuspense = () => (
    <Suspense fallback={<div>Loading...</div>}>
      <InternalServerError />
    </Suspense>
  );

  if (isLoading) return contentDefaultLoader();

  return (
    <Sentry.ErrorBoundary fallback={<InternalServerErrorWithSuspense />}>
      <Suspense fallback={contentDefaultLoader()}>
        <RouterProvider router={router} />
      </Suspense>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
