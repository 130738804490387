import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../Axios/Axios';

const GenerateTokenURL = 'api/generate-token';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/* structure of request to be
@request = 
{
    'user_id':2, -> user id
 } 
*/

export const generateToken = createAsyncThunk(
  'generateToken',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(GenerateTokenURL, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GenerateTokenSlice = createSlice({
  name: 'GenerateToken',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate token state handling */
      .addCase(generateToken.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(generateToken.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(generateToken.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action.payload.response;
      });
  },
});

export const getGenerateTokenState = (state) => state.GenerateToken;
export default GenerateTokenSlice.reducer;
