import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const url = 'host-company/internship-plan/get-details/';
const commonUrl = 'common-services/get-io-details';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};

/* structure of request params to be
@requestParams = 
{
    'id': 2
 } 
 */

export const getInternshipPlanById = createAsyncThunk(
  'getInternshipPlan',
  async (request, { rejectWithValue }) => {
    const { isInterview } = request;
    if (!isInterview) {
      const result = await axios
        .get(url + request?.payload?.id)
        .then((response) => response.data)
        .catch((error) => {
          throw rejectWithValue(error);
        });
      return result;
    }
    const { payload } = request || {};
    const result = await axios
      .post(commonUrl, payload)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const GetInternshipPlanSlice = createSlice({
  name: 'GetInternshipPlan',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInternshipPlanById.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(getInternshipPlanById.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(getInternshipPlanById.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getInternshipPlanByIdState = (state) => state.GetInternshipPlan;
export default GetInternshipPlanSlice.reducer;
