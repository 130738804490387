import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const CreatePaymentUrl = 'intern-service/intern-application/payment/create';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const createPayment = createAsyncThunk(
  'createPayment',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(CreatePaymentUrl, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const CreatePaymentSlice = createSlice({
  name: 'CreatePayment',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate get list of intern state handling */
      .addCase(createPayment.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action.payload;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.status = 'Failed';
        state.response = action.payload;
      });
  },
});

export const createPaymentState = (state) => state.CreatePayment;
export default CreatePaymentSlice.reducer;
