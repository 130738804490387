import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const deleteAlternatePOCUrl = '/host-company/admin-setting/delete-alternatePoc-detail/';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const deleteAlternatePOC = createAsyncThunk(
  'deleteAlternatePOC',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .delete(deleteAlternatePOCUrl + request.user_id)
      .then((response) => response?.data?.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  }
);

const DeleteAlternatePOCSlice = createSlice({
  name: 'DeleteAlternatePOC',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate delete poc state handling */
      .addCase(deleteAlternatePOC.pending, (state) => ({ ...state, status: 'Pending' }))
      .addCase(deleteAlternatePOC.fulfilled, (state, action) => ({
        ...state,
        status: action?.payload?.isSuccess ? 'Success' : 'Failed',
        response: action?.payload?.payload,
        message: !action.payload.isSuccess ? action.payload.message : '',
      }))
      .addCase(deleteAlternatePOC.rejected, (state) => ({ ...state, status: 'Failed' }));
  },
});

export const getDeleteAlternatePOCState = (state) => state.DeleteAlternatePOC;
export default DeleteAlternatePOCSlice.reducer;
