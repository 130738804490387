import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../../Axios/AxiosInterceptor';

export interface InterviewTime {
  status: number;
  data: {
    message: string;
    isSuccess: boolean;
    payload: {
      minimum_date: string;
      maximum_date: string;
    };
  };
}

const GetSInterviewTime = 'host-company/dashboard/interview/time';

export const GetInterviewTimeApi = createApi({
  reducerPath: 'interviewTimeApi',
  baseQuery: customBaseQuery,
  tagTypes: ['interviewTimeApi'],
  endpoints: (builder) => ({
    getInterviewTime: builder.query<InterviewTime, number>({
      query: (id) => ({
        url: `${GetSInterviewTime}/${id}`,
        method: 'GET',
        body: null,
      }),
      providesTags: ['interviewTimeApi'],
    }),
  }),
});

export const { useGetInterviewTimeQuery, useLazyGetInterviewTimeQuery } = GetInterviewTimeApi;

export default GetInterviewTimeApi.reducer;
