import { createSlice } from '@reduxjs/toolkit';

const saveTokenActionSlice = createSlice({
  name: 'saveTokenAction',
  initialState: {
    token: null,
  },
  reducers: {
    saveToken: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveToken } = saveTokenActionSlice.actions;

export default saveTokenActionSlice.reducer;
