import { createSlice } from '@reduxjs/toolkit';

const shortlistTooltipSlice = createSlice({
  name: 'shortlistTooltip',
  initialState: false, // Set your initial value here
  reducers: {
    showShortlistTooltip: (state, action) => {
      return action.payload; // Set the value in the Redux store
    },
  },
});

export const { showShortlistTooltip } = shortlistTooltipSlice.actions;

export default shortlistTooltipSlice.reducer;
