import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const SubmitWeeklyFeedbackUrl = 'intern-service/weekly-feedback-loop/complete-weekly-feedback';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const submitWeeklyFeedback = createAsyncThunk(
  'submitWeeklyFeedback',
  async (request, { rejectWithValue }) => {
    const result = await axios
      .post(SubmitWeeklyFeedbackUrl, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SubmitWeeklyFeedbackSlice = createSlice({
  name: 'SubmitWeeklyFeedback',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate save feedback state handling */
      .addCase(submitWeeklyFeedback.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(submitWeeklyFeedback.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(submitWeeklyFeedback.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const submitWeeklyFeedbackState = (state) => state.SubmitWeeklyFeedback;
export default SubmitWeeklyFeedbackSlice.reducer;
