import _throttle from 'lodash/throttle';

import { updateLastActiveTimeApiCall } from '../../../Redux/Services/common';

export const handleUpdateLastActiveTime = _throttle(
  () => {
    updateLastActiveTimeApiCall();
  },
  60000,
  { leading: true, trailing: false },
);
