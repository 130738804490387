import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';
import {
  UpdateCalendarParams,
  UpdateCalendarResponse,
  GetCalendarListResponse,
  DeleteCalendarParams,
  DeleteCalendarResponse,
  CreateCalendarResponse,
  SyncedCalendarsServiceProvidersResponseType,
} from './CalendarApiTypes';

const getCalendarListUrl = 'host-company/integrate/user/calendars';
const commonUrl = '/host-company/integrate/user/calendar';

export const GetCalendarListApi = createApi({
  reducerPath: 'calendarListApi',
  baseQuery: customBaseQuery,
  tagTypes: ['calendarListApi', 'GetCalendarList', 'CreateCalendar'],
  endpoints: (builder) => ({
    getCalendarList: builder.query<GetCalendarListResponse, null>({
      query: (body) => ({
        url: `${getCalendarListUrl}`,
        method: 'GET',
        body: body,
      }),
      providesTags: ['GetCalendarList'],
    }),
    createCalendar: builder.mutation<CreateCalendarResponse, { token: string }>({
      query: ({ token }) => ({
        url: `${commonUrl}`,
        method: 'POST',
        body: {
          token,
          calendar_provider: 'GOOGLE',
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['GetCalendarList']),
    }),
    updateCalendar: builder.mutation<UpdateCalendarResponse, UpdateCalendarParams>({
      query: ({ calendarId, calendarName }) => ({
        url: `${commonUrl}/${calendarId}`,
        method: 'PATCH',
        body: {
          calendar_name: calendarName,
        },
      }),
      invalidatesTags: (result, error) => (error ? [] : ['GetCalendarList']),
    }),
    disconnectCalendar: builder.mutation<DeleteCalendarResponse, DeleteCalendarParams>({
      query: ({ calendarId }) => ({
        url: `${commonUrl}/${calendarId}`,
        method: 'DELETE',
        body: null,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['GetCalendarList']),
    }),
    getSyncedCalendarServiceProviders: builder.query<
      SyncedCalendarsServiceProvidersResponseType,
      null
    >({
      query: () => ({
        url: `${getCalendarListUrl}/provider`,
        method: 'GET',
        body: null,
      }),
    }),
  }),
});

export const {
  useGetCalendarListQuery,
  useCreateCalendarMutation,
  useUpdateCalendarMutation,
  useDisconnectCalendarMutation,
  useGetSyncedCalendarServiceProvidersQuery,
} = GetCalendarListApi;
export default GetCalendarListApi.reducer;
