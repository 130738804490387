import {  createSlice } from '@reduxjs/toolkit';

const initialState = {
  mode: '',
  name : '',
  metaData: '',
};
const InvokeGlobalPopupSlice = createSlice({
  name: 'InvokeGlobalPopup',
  initialState,
  reducers: {
    setPopupDetails: (state, action) => {
        state.mode = action?.payload?.mode;  
        state.name = action?.payload?.name; 
        state.metaData = action?.payload?.metaData; 

    },
   
  },
});

export const getPopupDetails = (state) => state.InvokeGlobalPopup;
export const { setPopupDetails } = InvokeGlobalPopupSlice.actions;

export default InvokeGlobalPopupSlice.reducer;
