import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const savePhotosUrl = '/host-company/profile/save-images';
const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const savePhotos = createAsyncThunk('savePhotos', async (request, { rejectWithValue }) => {
  const result = await axios
    .post(savePhotosUrl, request)
    .then((response) => response?.data?.data)
    .catch((error) => {
      throw rejectWithValue(error);
    });
  return result;
});



const SavePhotosSlice = createSlice({
  name: 'SavePhotos',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate saving photos state handling */
      .addCase(savePhotos.pending, (state) => {
        state.status = 'Pending';
      })
      .addCase(savePhotos.fulfilled, (state, action) => {
        state.status = 'Success';
        state.response = action?.payload?.payload;
      })
      .addCase(savePhotos.rejected, (state) => {
        state.status = 'Failed';
      });
  },
});

export const getSavePhotosState = (state) => state.SavePhotos;
export default SavePhotosSlice.reducer;
