import { createApi } from '@reduxjs/toolkit/query/react';
import intl from 'react-intl-universal';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';

const currentLocale = 'en-US';
export const LoadLocalesApi = createApi({
  reducerPath: 'loadLocalesApi',
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    loadLocals: builder.query({
      query: () => {
        require('intl/locale-data/jsonp/en.js');
        return {
          method: 'GET',
          url: `locales/${currentLocale}.json`,
          body: null,
          includeFEBaseUrl: true,
        };
      },
      transformResponse: (response) => {
        intl.init({
          currentLocale,
          locales: {
            [currentLocale]: response,
          },
        });
        return response;
      },
    }),
  }),
});

export const { useLoadLocalsQuery } = LoadLocalesApi;
export default LoadLocalesApi.reducer;
