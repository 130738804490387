import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../Axios/Axios';

const SaveActiveHoursUrl = 'intern-service/weekly-feedback-loop/question/';

const initialState = {
  status: 'idle',
  error: null,
  response: null,
};
export const saveActiveHours = createAsyncThunk(
  'saveActiveHours',
  async (request, { rejectWithValue }) => {
    const weeklyFeedbackId = request.weekly_feedback_id;
    delete request.weekly_feedback_id;
    const result = await axios
      .post(`${SaveActiveHoursUrl + weeklyFeedbackId}/intern-active-work-hours`, request)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error);
      });
    return result;
  },
);

const SaveActiveHoursSlice = createSlice({
  name: 'SaveActiveHours',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* generate save active hours state handling */
      .addCase(saveActiveHours.pending, (state) => ({
        ...state,
        status: 'Pending',
      }))
      .addCase(saveActiveHours.fulfilled, (state, action) => ({
        ...state,
        status: action.payload?.data?.isSuccess ? 'Success' : 'Failed',
        response: action.payload,
      }))
      .addCase(saveActiveHours.rejected, (state) => ({
        ...state,
        status: 'Failed',
      }));
  },
});

export const saveActiveHoursState = (state) => state.SaveActiveHours;
export default SaveActiveHoursSlice.reducer;
