import { useEffect } from 'react';
import _noop from 'lodash/noop';

interface IUseCaptureLastSeenProps {
  shouldCapture: boolean;
  onUpdateLastActiveTime: () => void;
}

const useCaptureLastSeen = ({
  shouldCapture = false,
  onUpdateLastActiveTime = _noop,
}: IUseCaptureLastSeenProps) => {
  useEffect(() => {
    const events = ['mousemove', 'touchmove', 'click', 'keypress', 'online', 'offline'];

    const addEventListeners = () => {
      events.forEach((event) => window.addEventListener(event, onUpdateLastActiveTime));
      document.addEventListener('visibilitychange', onUpdateLastActiveTime);
    };

    const removeEventListeners = () => {
      events.forEach((event) => window.removeEventListener(event, onUpdateLastActiveTime));
      document.removeEventListener('visibilitychange', onUpdateLastActiveTime);
    };

    if (shouldCapture) {
      addEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }, [shouldCapture, onUpdateLastActiveTime]);
};

export default useCaptureLastSeen;
