import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../../../Axios/AxiosInterceptor';

const deleteFileURL = 'common-services/delete-file';

export const DeleteFileApi = createApi({
  reducerPath: 'DeleteFileApi',
  baseQuery: customBaseQuery,
  tagTypes: ['DeleteFile'],

  endpoints: (builder) => ({
    deleteFile: builder.mutation({
      query: (request) => ({
        url: deleteFileURL,
        method: 'POST',
        body: request,
      }),
      invalidatesTags: ['DeleteFile'],
    }),
  }),
});
export const { useDeleteFileMutation } = DeleteFileApi;
export default DeleteFileApi.reducer;
